import React from "react";
import PropTypes from "prop-types";

const PageNavigation = ({
  previousPage,
  nextPage,
  currentPage,
  applyPageNumber,
}) => {
  const getPreviousPage = () => {
    if (previousPage) {
      const page = previousPage.split("=")[1];
      if (page && Number(page) > 0) {
        applyPageNumber(page);
      }
    }
  };

  const getNextPage = () => {
    if (nextPage) {
      const page = nextPage.split("=")[1];
      if (page && Number(page) > 0) {
        applyPageNumber(page);
      }
    }
  };

  return (
    <div className="user__paginate">
      <div>
        {previousPage ? (
          <button onClick={getPreviousPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffffff"
                d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
              />
            </svg>
          </button>
        ) : (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffffff"
                d="M15.125 21.1L6.7 12.7q-.15-.15-.213-.325T6.425 12q0-.2.062-.375T6.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L9.55 12l7.35 7.35q.35.35.35.863t-.375.887q-.375.375-.875.375t-.875-.375Z"
              />
            </svg>
          </span>
        )}

        <p>Page {currentPage}</p>

        {nextPage ? (
          <button onClick={getNextPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffffff"
                d="M7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375t.887.375l8.4 8.425q.15.15.213.325T17.6 12q0 .2-.063.375t-.212.325L8.9 21.125q-.35.35-.863.35T7.15 21.1Z"
              />
            </svg>
          </button>
        ) : (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ffffff"
                d="M7.15 21.1q-.375-.375-.375-.888t.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375t.887.375l8.4 8.425q.15.15.213.325T17.6 12q0 .2-.063.375t-.212.325L8.9 21.125q-.35.35-.863.35T7.15 21.1Z"
              />
            </svg>
          </span>
        )}
      </div>
    </div>
  );
};

PageNavigation.propTypes = {
  previousPage: PropTypes.string,
  nextPage: PropTypes.string,
  totalRecord: PropTypes.number,
  currentRecord: PropTypes.number,
  currentPage: PropTypes.number,
  applyPageNumber: PropTypes.func,
};

export default PageNavigation;
