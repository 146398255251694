import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../store/actions/password";
import ButtonLoader from "../components/ButtonLoader";
import { resetPasswordState } from "../store/slices/password";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const bottonLoading = useSelector((state) => state.password.bottonLoading);
  const errorMessage = useSelector((state) => state.password.errorMessage);
  const successMessage = useSelector((state) => state.password.successMessage);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      setEmail("");
      navigate(`/`);
    }

    return () => {
      dispatch(resetPasswordState());
    };
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    if (email.trim() === "") {
      toast.error("Your email is required.");
      return;
    }

    if (/\S+@\S+\.\S+/g.test(email.trim()) === false) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const payload = {
      email,
    };

    dispatch(forgotPassword(payload));
  };

  return (
    <section className="grid lg:grid-cols-2">
      <div className="h-full overflow-auto  py-12 z-50 w-full">
        <div className="container">
          <div className="sm:w-9/12 mx-auto hidden lg:block">
            <div className="mx-auto lg:mx-0 w-48">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-9/12 sm:mx-auto mt-10 lg:mt-20">
            <div className="mb-6 w-36 block lg:hidden">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-6">
              <h3 className="text-2xl md:text-5xl text-black font-black">
                Forgot Password.
              </h3>
              <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                Please enter your email to reset your password.
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Email
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <p className="text-right">
                <Link
                  to="/"
                  className="text-sm text-jr_pink font-bold sm:text-base"
                >
                  Remember Password?
                </Link>
              </p>

              <div className="flex">
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      {" "}
                      Submit
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" z-50  hidden lg:block bg-cover bg-no-repeat bg-[50%] bg-jr_auth fixed right-0 top-0 w-1/2 h-full ">
        <div className="bg-[#00000080] h-full  pt-12 pr-12">
          <div className="w-48 ml-auto">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/76e1df154768775.6365a98fb0df9.png"
              alt="joyribbons"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
