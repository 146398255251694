import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../actions/auth";

const initialState = {
  bottonLoading: false,
  errorMessage: "",
  successMessage: "",
  user: null,
  expireTimer: -1,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = false;
    },
    logOut: (state) => {
      state.user = null;
      state.expireTimer = -1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.user = action.payload.data;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
    });
  },
});

export const { resetAuthState, logOut } = authSlice.actions;
export default authSlice.reducer;
