import React from "react";
import PropTypes from "prop-types";

const ErrorFound = ({ title = "Oops!! Something went wrong" }) => {
  return (
    <>
      <div className="flex flex-col gap-6 items-center justify-center">
        <div className="h-[100px]">
          <img src="/images/error.png" alt="Empty" className="h-[100px]" />
        </div>
        <p className="text-center font-semibold text-lg sm:text-3xl text-jr_blue">
          {title}
        </p>
      </div>
    </>
  );
};

ErrorFound.propTypes = {
  title: PropTypes.string,
};

export default ErrorFound;
