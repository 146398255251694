import React, { Fragment } from "react";
import PropTypes from "prop-types";

const MessagesTable = ({ data }) => {
  console.log({ data });
  return (
    <div className="bg-white">
      <div className="overflow-auto horizontal-scroll">
        <table className="min-w-[800px] lg:min-w-full w-full  border-collapse custom-table">
          <thead>
            <tr className="border-none">
              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Name
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Email
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Well Wish Messages
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <Fragment key={item.id}>
                <tr className="border-none rounded-lg bg-jr_neutral shadow-md">
                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-l-lg">
                    {item.sender_name}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate">
                    {item.sender_email}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none  rounded-r-lg">
                    <p className=" w-[300px]">{item.message}</p>
                  </td>
                </tr>

                <tr className="!bg-transparent">
                  <td
                    colSpan="100"
                    className="p-0 h-2 rounded-none bg-transparent"
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

MessagesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MessagesTable;
