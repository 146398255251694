import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { toThousandFormat, toFriendlyDate } from "../../Constant";

const AmountFromPurchasedGiftsTable = ({ data }) => {
  return (
    <div className="bg-white">
      <div className="overflow-auto horizontal-scroll">
        <table className="min-w-[800px] lg:min-w-full w-full  border-collapse custom-table">
          <thead>
            <tr className="border-none">
              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Amount
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Transaction Type
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Transaction Reference
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Status
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <Fragment key={item.id}>
                <tr className="border-none rounded-lg bg-jr_neutral shadow-md">
                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-l-lg">
                    ₦{toThousandFormat(item.amount)}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate">
                    {item.channel}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate">
                    {item.channel === "CONTRIBUTION"
                      ? `CONTRIBUTION FOR GIFT ID: ${item.reference} `
                      : item.reference}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate">
                    {item.status === "PAID" && (
                      <span className=" text-jr_green">PAYMENT APPROVED</span>
                    )}
                    {item.status === "ACTIVE" && <span>NOT PAID</span>}
                    {item.status === "PENDING" && (
                      <span className=" text-jr_yellow">
                        WITHDRAWAL REQUESTED
                      </span>
                    )}
                    {item.status === "REJECTED" && (
                      <span className=" text-jr_red">WITHDRAWAL DECLINED</span>
                    )}
                    {item.reason && (
                      <span>
                        <br />
                        REASON:
                        <br />
                        {item.reason}
                      </span>
                    )}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-r-lg">
                    {toFriendlyDate(item.updated_at)}
                  </td>
                </tr>

                <tr className="!bg-transparent">
                  <td
                    colSpan="100"
                    className="p-0 h-2 rounded-none bg-transparent"
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

AmountFromPurchasedGiftsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AmountFromPurchasedGiftsTable;
