import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateSelectedGift } from "../store/actions/gift";
import { resetGiftState } from "../store/slices/gift";
import ButtonLoader from "../components/ButtonLoader";
import { useNavigate } from "react-router-dom";

const AddOwnList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    giftName: "",
    giftDescription: "",
    giftAmount: "",
    giftFileUpload: "",
    giftFileName: "",
  });

  const bottonLoading = useSelector((state) => state.gift.bottonLoading);
  const errorMessage = useSelector((state) => state.gift.errorMessage);
  const successMessage = useSelector((state) => state.gift.successMessage);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      navigate("/wedding-gifts/selected-gifts");
    }

    return () => {
      dispatch(resetGiftState());
    };
  }, [errorMessage, successMessage]);

  const getGift = (event) => {
    toast.dismiss();

    const file = event.target.files[0];

    setFormDetails({
      ...formDetails,
      giftFileUpload: null,
      giftFileName: "",
    });

    if (file) {
      const ImgExt = file.name.split(".").pop();

      if (
        ImgExt.toLowerCase() !== "png" &&
        ImgExt.toLowerCase() !== "jpg" &&
        ImgExt.toLowerCase() !== "jpeg"
      ) {
        setFormDetails({
          ...formDetails,
          giftFileUpload: null,
          giftFileName: "",
        });
        toast.error("Uploaded file must be png, jpg, jpeg or pdf.");
        return;
      }
      if (file.size > 1000000) {
        setFormDetails({
          ...formDetails,
          giftFileUpload: null,
          giftFileName: "",
        });
        toast.error("Uploaded file must be png, jpg, jpeg or pdf.");
        return;
      }

      setFormDetails({
        ...formDetails,
        giftFileUpload: file,
        giftFileName: file.name,
      });
    }
  };

  const submitForm = async () => {
    toast.dismiss();

    const validateData =
      formDetails.giftName.trim() !== "" &&
      formDetails.giftDescription.trim() !== "" &&
      formDetails.giftAmount.trim() !== "" &&
      formDetails.giftFileUpload !== null &&
      formDetails.giftFileUpload !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    const payload = new FormData();
    payload.append(`gift_name`, formDetails.giftName.trim());
    payload.append(`gift_description`, formDetails.giftDescription.trim());
    payload.append(`gift_amount`, formDetails.giftAmount.trim());
    payload.append(`image_upload`, formDetails.giftFileUpload);

    dispatch(updateSelectedGift(payload));
  };

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Add My Own List
                </h3>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="lg:w-1/2">
              <div className="grid gap-5">
                <div>
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Gift Name
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <input
                      className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                      type="text"
                      placeholder="Wristwatch"
                      value={formDetails.giftName}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          giftName: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Gift Description
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <textarea
                      className="h-[100px] bg-transparent text-black text-sm  font-semibold  p-6 w-full"
                      type="text"
                      placeholder="Wristwatch"
                      value={formDetails.giftDescription}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          giftDescription: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                </div>

                <div>
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Gift Amount (₦)
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <input
                      className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                      type="text"
                      placeholder="₦20,000"
                      value={formDetails.giftAmount}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          giftAmount: e.target.value.replace(/\D/g, ""),
                        })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Upload Image
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <div className="flex relative justify-center items-center flex-col h-12">
                      <div className="flex flex-col">
                        {!formDetails.giftFileName && (
                          <p className="text-sm sm:text-base text-jr_slateGray font-semibold  cursor-pointer">
                            click to select and add images
                          </p>
                        )}

                        {formDetails.giftFileName && (
                          <p className="text-sm sm:text-base text-jr_slateGray font-semibold  cursor-pointer">
                            {formDetails.giftFileName}
                          </p>
                        )}

                        <input
                          type="file"
                          className="absolute inset-0 opacity-0"
                          onChange={(event) => getGift(event)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="max-w-[250px] mx-auto mt-12">
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Save & continue
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOwnList;
