import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { toThousandFormat } from "../../Constant";

const SelectedGiftsTable = ({ data, removeGift }) => {
  console.log(data);
  return (
    <div className="bg-white">
      <div className="overflow-auto horizontal-scroll">
        <table className="min-w-[800px] lg:min-w-full w-full  border-collapse custom-table">
          <thead>
            <tr className="border-none">
              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Image
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Name
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Description
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Price
              </th>

              <th className="w-[200px] overflow-visible  text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <Fragment key={item.id}>
                <tr className="border-none rounded-lg bg-jr_neutral shadow-md">
                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-l-lg">
                    <img
                      src={item.gift_image}
                      alt={item.gift_name}
                      className="w-[200px]"
                    />
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none">
                    {item.gift_name}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none">
                    {item.gift_description}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate">
                    ₦{toThousandFormat(item.gift_amount)}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-r-lg">
                    <button
                      className="relative"
                      onClick={() => removeGift(item)}
                    >
                      <p className="text-sm text-jr_pink underline cursor-pointer flex font-semibold justify-center">
                        REMOVE
                      </p>
                    </button>
                  </td>
                </tr>

                <tr className="!bg-transparent">
                  <td
                    colSpan="100"
                    className="p-0 h-2 rounded-none bg-transparent"
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SelectedGiftsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  removeGift: PropTypes.func,
};

export default SelectedGiftsTable;
