import { createSlice } from "@reduxjs/toolkit";
import { getOverview } from "../actions/overview";

const initialState = {
  analytics: null,
  pageLoading: false,
  errorState: false,
};

export const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    resetOverviewState: (state) => {
      state.errorState = false;
      state.pageLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOverview.pending, (state) => {
      state.pageLoading = true;
      state.errorState = false;
      state.errorMessage = "";
    });
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.analytics = action.payload.data;
      state.errorMessage = "";
      state.pageLoading = false;
    });
    builder.addCase(getOverview.rejected, (state, action) => {
      console.log({ action });
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetOverviewState } = overviewSlice.actions;
export default overviewSlice.reducer;
