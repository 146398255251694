import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateAccount } from "../store/actions/account";
import { resetAccountState } from "../store/slices/account";
import { useAuthState } from "../context/AuthContext";
import ButtonLoader from "../components/ButtonLoader";

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const { user: userData } = useAuthState();
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const bottonLoading = useSelector((state) => state.account.bottonLoading);
  const errorMessage = useSelector((state) => state.account.errorMessage);
  const successMessage = useSelector((state) => state.account.successMessage);

  useEffect(() => {
    setFormDetails({
      ...formDetails,
      firstName: userData.user.first_name || "",
      lastName: userData.user.last_name || "",
      phone: userData.user.phone || "",
      email: userData.user.email || "",
    });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
    }

    return () => {
      dispatch(resetAccountState());
    };
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    const validateData =
      formDetails.firstName.trim() !== "" &&
      formDetails.lastName.trim() !== "" &&
      formDetails.phone.trim() !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    if (/^[0-9]+$/g.test(formDetails.phone.trim()) === false) {
      toast.error(
        "Please enter a valid phone number, which will only be numbers."
      );
      return;
    }

    const payload = {
      first_name: formDetails.firstName.trim(),
      last_name: formDetails.lastName.trim(),
      phone: formDetails.phone.trim(),
    };

    dispatch(updateAccount(payload));
  };

  return (
    <div>
      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          First Name
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="text"
            placeholder="Enter your first name"
            value={formDetails.firstName}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                firstName: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Last name
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="text"
            placeholder="Enter your last name"
            value={formDetails.lastName}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                lastName: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Email (Not Editable)
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="email"
            disabled
            placeholder="Enter your email"
            defaultValue={formDetails.email}
          />
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Phone Number
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="tel"
            placeholder="Enter your phone number"
            value={formDetails.phone}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                phone: e.target.value.replace(/\D/g, ""),
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-auto sm:ml-auto sm:mr-0 mt-12">
          {bottonLoading ? (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              disabled
            >
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              onClick={submitForm}
            >
              <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                Update Profile Details
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
