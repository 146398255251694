import { createSlice } from "@reduxjs/toolkit";
import {
  getCategories,
  getGiftCategories,
  addSelectedGift,
} from "../actions/category";

const initialState = {
  giftCategories: [],
  giftCategoriesList: null,
  emptyState: false,
  errorState: false,
  pageLoading: false,
  previousPage: "",
  nextPage: "",
  totalRecord: 0,
  currentPage: 1,
  errorMessage: "",
  successMessage: "",
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    resetCategoryState: (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    addSelectedGiftToState: (state, action) => {
      if (
        state.giftCategoriesList?.gifts &&
        state.giftCategoriesList.gifts.length > 0
      ) {
        const giftList = state.giftCategoriesList.gifts.filter(
          (item) => item.id !== action.payload
        );

        if (giftList) {
          state.giftCategoriesList.gifts = giftList;

          console.log(state.giftCategoriesList.gifts.length);

          if (state.giftCategoriesList.gifts.length === 0) {
            state.emptyState = true;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.giftCategories = action.payload.data;

      if (state.giftCategories.length === 0) {
        state.emptyState = true;
      }

      state.pageLoading = false;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(getGiftCategories.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getGiftCategories.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.giftCategoriesList = action.payload.data;

      if (
        state.giftCategoriesList?.gifts &&
        state.giftCategoriesList.gifts.length === 0
      ) {
        state.emptyState = true;
      }
    });
    builder.addCase(getGiftCategories.rejected, (state, action) => {
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(addSelectedGift.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(addSelectedGift.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(addSelectedGift.rejected, (state, action) => {
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetCategoryState, addSelectedGiftToState } =
  categorySlice.actions;
export default categorySlice.reducer;
