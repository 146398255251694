import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { toThousandFormat, toFriendlyDate } from "../../Constant";

const GiftsPurchasedTable = ({ data }) => {
  return (
    <div className="bg-white">
      <div className="overflow-auto horizontal-scroll">
        <table className="min-w-[800px] lg:min-w-full w-full  border-collapse custom-table">
          <thead>
            <tr className="border-none">
              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Gift
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Sender Details
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                <p>Order Number</p>
                <p>Transaction Reference</p>
                <p>Date</p>
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                <p>Amount</p>
                <p>QTY</p>
                <p>Total</p>
              </th>

              <th className="text-left font-semibold text-sm sm:text-base text-jr_pink p-6 border-none">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <Fragment key={index}>
                <tr className="border-none rounded-lg bg-jr_neutral shadow-md">
                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-l-lg">
                    {item.gift_title}
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate ">
                    <p>{item.sender_name}</p>
                    <p>{item.sender_email}</p>
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate ">
                    <p>{item.order_number}</p>
                    <p>{item.transaction_reference}</p>
                    <p>{toFriendlyDate(item.updated_at)}</p>
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate ">
                    <p>₦{toThousandFormat(item.amount)}</p>
                    <p>{item.qty}</p>
                    <p>₦{toThousandFormat(item.total)}</p>
                  </td>

                  <td className="text-left font-medium text-sm sm:text-base text-jr_lightBlack p-6 border-none truncate rounded-r-lg">
                    {item.status === "PAID" && (
                      <span className=" text-jr_green">{item.status}</span>
                    )}
                    {item.status === "CANCELLED" && (
                      <span className=" text-jr_red">{item.status}</span>
                    )}
                    {item.status === "PENDING" && (
                      <span className=" text-jr_yellow">{item.status}</span>
                    )}
                  </td>
                </tr>

                <tr className="!bg-transparent">
                  <td
                    colSpan="100"
                    className="p-0 h-2 rounded-none bg-transparent"
                  ></td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GiftsPurchasedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GiftsPurchasedTable;
