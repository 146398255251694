import { createSlice } from "@reduxjs/toolkit";
import { getRegistryDetails, createRegistry } from "../actions/registry";

const initialState = {
  registryDetails: null,
  emptyState: false,
  errorState: false,
  pageLoading: false,
  errorMessage: "",
  successMessage: "",
};

export const registrySlice = createSlice({
  name: "registry",
  initialState,
  reducers: {
    resetRegistryState: (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.registryDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegistryDetails.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getRegistryDetails.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.registryDetails = action.payload.data;
    });
    builder.addCase(getRegistryDetails.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(createRegistry.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(createRegistry.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(createRegistry.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetRegistryState } = registrySlice.actions;
export default registrySlice.reducer;
