import React from "react";
import { RxArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  let navigate = useNavigate();

  return (
    <>
      <button onClick={() => navigate(-1)} className="py-2">
        <h3 className="h-10 w-10 bg-transparent hover:bg-jr_neutral rounded-full font-black text-jr_pink flex justify-center gap-2 items-center">
          <RxArrowLeft size={20} />
        </h3>
      </button>
    </>
  );
};

export default BackButton;
