import React, { useEffect } from "react";
import { Outlet, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "./context/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/Register.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import VerifyEmail from "./pages/VerifyEmail.js";
import VerifiedEmail from "./pages/VerifiedEmail.js";
import Overview from "./pages/Overview.js";
import RegistryLink from "./pages/RegistryLink.js";
import Login from "./pages/Login.js";
import Registry from "./pages/Registry.js";
import WeddingGifts from "./pages/WeddingGifts.js";
import Profile from "./pages/Profile.js";
import Header from "./components/Header.js";
import GiftCenter from "./pages/GiftCenter.js";
import WeddingDetails from "./pages/WeddingDetails.js";
import LoveStory from "./pages/LoveStory.js";
import WeddingPictures from "./pages/WeddingPictures.js";
import CashGifts from "./pages/CashGifts.js";
import Analytics from "./pages/Analytics.js";
import SearchWeddingVenue from "./pages/SearchWeddingVenue.js";
import Messages from "./pages/Messages.js";
import Rsvp from "./pages/Rsvp.js";
import GiftsPurchased from "./pages/GiftsPurchased.js";
import AmountFromPurchasedGifts from "./pages/AmountFromPurchasedGifts.js";
import JoyRibbonsCollection from "./pages/JoyRibbonsCollection.js";
import SelectedGifts from "./pages/SelectedGifts.js";
import AddOwnList from "./pages/AddOwnList.js";

function App() {
  const { isAuth } = useAuthState();

  const Layout = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scroll({ top: 0, behavior: `smooth` });
    }, [pathname]);

    if (!isAuth) {
      return <Navigate to="/" />;
    }

    return (
      <div className="app">
        <Header />
        <Outlet />
      </div>
    );
  };

  return (
    <div>
      <Routes>
        <Route index={true} path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email/:id" element={<VerifyEmail />} />
        <Route path="/verify-email-success" element={<VerifiedEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        {
          <Route element={<Layout />}>
            <Route path="/overview" element={<Overview />} />
            <Route path="/analytics/rsvp" element={<Rsvp />} />
            <Route path="/analytics/messages" element={<Messages />} />
            <Route
              path="/analytics/gifts-purchased"
              element={<GiftsPurchased />}
            />
            <Route
              path="/analytics/amount-purchased"
              element={<AmountFromPurchasedGifts />}
            />
            <Route path="/registry-link" element={<RegistryLink />} />
            <Route path="/wedding-registry" element={<Registry />} />
            <Route path="/wedding-details" element={<WeddingDetails />} />
            <Route path="/love-story" element={<LoveStory />} />
            <Route path="/wedding-pictures" element={<WeddingPictures />} />
            <Route path="/wedding-gifts" element={<WeddingGifts />} />
            <Route
              path="/wedding-gifts/joyribbons-collection"
              element={<JoyRibbonsCollection />}
            />
            <Route
              path="/wedding-gifts/selected-gifts"
              element={<SelectedGifts />}
            />
            <Route path="/wedding-gifts/add-list" element={<AddOwnList />} />
            <Route path="/cash-gifts" element={<CashGifts />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/wedding-venue" element={<SearchWeddingVenue />} />
            <Route path="/gifts-center/:id" element={<GiftCenter />} />
            <Route path="/profile" element={<Profile />} />
          </Route>
        }
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer
        autoClose={10000}
        hideProgressBar={true}
        newestOnTop={true}
        theme="dark"
        position="bottom-left"
      />
    </div>
  );
}

export default App;
