import { createSlice } from "@reduxjs/toolkit";
import { getRsvp } from "../actions/rsvp";

const initialState = {
  dataList: [],
  emptyState: false,
  errorState: false,
  pageLoading: false,
  previousPage: "",
  nextPage: "",
  totalRecord: 0,
  currentPage: 1,
  errorMessage: "",
  successMessage: "",
};

export const rsvpSlice = createSlice({
  name: "rsvp",
  initialState,
  reducers: {
    resetRsvpState: (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRsvp.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getRsvp.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.dataList = action.payload.data.data;
      state.previousPage = action.payload.data.prev_page_url;
      state.nextPage = action.payload.data.next_page_url;
      state.currentPage = action.payload.data.current_page;
      state.totalRecord = action.payload.data.total;

      if (state.dataList.length === 0) {
        state.emptyState = true;
      }

      state.pageLoading = false;
    });
    builder.addCase(getRsvp.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetRsvpState } = rsvpSlice.actions;
export default rsvpSlice.reducer;
