import React from "react";
import { Link } from "react-router-dom";

const VerifiedEmail = () => {
  return (
    <section className="grid lg:grid-cols-2">
      <div className="h-full overflow-auto  py-12 z-50 w-full">
        <div className="container">
          <div className="sm:w-9/12 mx-auto hidden lg:block">
            <div className="mx-auto lg:mx-0 w-48">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-9/12 sm:mx-auto mt-10 lg:mt-20">
            <div className="mb-6 w-36 block lg:hidden">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>

            <div className="flex flex-col justify-center items-center mb-14 gap-6 text-center">
              <h3 className="text-2xl md:text-5xl text-black font-black">Email Verified.</h3>
              <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                Congratulations!! Your email was successfully verified. Login and start using JoyRibbons.
              </p>
            </div>
            <form className="flex flex-col gap-5">
              <div className="flex">
                <Link
                  to="/"
                  className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                >
                  <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                    Go To Login
                  </span>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" z-50  hidden lg:block bg-cover bg-no-repeat bg-[50%] bg-jr_auth fixed right-0 top-0 w-1/2 h-full ">
        <div className="bg-[#00000080] h-full  pt-12 pr-12">
          <div className="w-48 ml-auto">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/76e1df154768775.6365a98fb0df9.png"
              alt="joyribbons"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifiedEmail;
