import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton";
import { useSelector, useDispatch } from "react-redux";
import { getCategories } from "../store/actions/category";
import { resetCategoryState } from "../store/slices/category";
import PageLoader from "../components/PageLoader";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";

const JoyRibbonsCollection = () => {
  const dispatch = useDispatch();

  const pageLoading = useSelector((state) => state.category.pageLoading);
  const errorMessage = useSelector((state) => state.category.errorMessage);
  const emptyState = useSelector((state) => state.category.emptyState);
  const errorState = useSelector((state) => state.category.errorState);
  const giftCategories = useSelector((state) => state.category.giftCategories);

  useEffect(() => {
    dispatch(getCategories());

    return () => {
      dispatch(resetCategoryState());
    };
  }, []);

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Joyribbons Collection
                </h3>
              </div>
            </div>
          </div>

          {pageLoading && <PageLoader />}
          {!pageLoading && emptyState && (
            <div>
              <NoRecordFound title="Oops! no record available at the moment. " />
            </div>
          )}
          {!pageLoading && errorState && (
            <div>
              <ErrorFound title={errorMessage} />
            </div>
          )}

          {giftCategories.length > 0 && (
            <div className="py-12">
              <div className="mb-12 text-center">
                <h3 className="text-xl sm:text-2xl text-jr_pink font-semibold mb-3 ">
                  Select Gifts You Would Love People To Buy For You
                </h3>
              </div>

              <div className="mb-12">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 my-6 gap-5">
                  {giftCategories.map((item, id) => {
                    return (
                      <Link
                        key={id}
                        to={`/gifts-center/${item.id}`}
                        style={{ backgroundImage: `url(${item.image})` }}
                        className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                      >
                        <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                          <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                            {item.name}
                          </h3>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default JoyRibbonsCollection;
