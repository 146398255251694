import { createSlice } from "@reduxjs/toolkit";
import {
  getWeddingLink,
  getWeddingDetails,
  updateWeddingDetails,
} from "../actions/wedding";

const initialState = {
  weddingDetails: null,
  weddingStories: null,
  weddingPictures: null,
  weddingLink: null,
  pageLoading: false,
  bottonLoading: false,
  errorState: false,
  errorMessage: "",
  successMessage: "",
};

export const weddingSlice = createSlice({
  name: "wedding",
  initialState,
  reducers: {
    resetWeddingState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.errorState = false;
      state.pageLoading = false;
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWeddingLink.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getWeddingLink.fulfilled, (state, action) => {
      state.weddingLink = action.payload.data;
      console.log(action.payload.data);

      state.pageLoading = false;

      if (!action.payload.data) {
        state.errorMessage =
          "You don't have any website link to share with family and friends. Please create a website, upload all necessary website details and generate a website link.";
        state.errorState = true;
      }
    });
    builder.addCase(getWeddingLink.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(getWeddingDetails.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getWeddingDetails.fulfilled, (state, action) => {
      state.weddingDetails = action.payload.data;
      state.pageLoading = false;
    });
    builder.addCase(getWeddingDetails.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(updateWeddingDetails.pending, (state) => {
      state.pageLoading = false;
      state.bottonLoading = true;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(updateWeddingDetails.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateWeddingDetails.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetWeddingState } = weddingSlice.actions;
export default weddingSlice.reducer;
