import { createSlice } from "@reduxjs/toolkit";
import {
  getGiftsPurchased,
  getWeddingCashGift,
  updateWeddingCashGift,
  updateSelectedGift,
  getSelectedGift,
  removeSelectedGift,
} from "../actions/gift";

const initialState = {
  giftsPurchasedList: [],
  weddingCashGifts: null,
  weddingSelectedGifts: [],
  emptyState: false,
  errorState: false,
  pageLoading: false,
  previousPage: "",
  nextPage: "",
  totalRecord: 0,
  currentPage: 1,
  errorMessage: "",
  successMessage: "",
};

export const giftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {
    resetGiftState: (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    removeSelectedGiftFromState: (state, action) => {
      const weddingSelectedGifts = state.weddingSelectedGifts.filter(
        (item) => item.id !== action.payload
      );

      if (weddingSelectedGifts) {
        state.weddingSelectedGifts = weddingSelectedGifts;

        if (state.weddingSelectedGifts.length === 0) {
          state.emptyState = true;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGiftsPurchased.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(getGiftsPurchased.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.giftsPurchasedList = action.payload.data.data;
      state.previousPage = action.payload.data.prev_page_url;
      state.nextPage = action.payload.data.next_page_url;
      state.currentPage = action.payload.data.current_page;
      state.totalRecord = action.payload.data.total;

      if (state.giftsPurchasedList.length === 0) {
        state.emptyState = true;
      }

      state.pageLoading = false;
    });
    builder.addCase(getGiftsPurchased.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(getWeddingCashGift.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getWeddingCashGift.fulfilled, (state, action) => {
      state.weddingCashGifts = action.payload.data;
      state.pageLoading = false;
    });
    builder.addCase(getWeddingCashGift.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(updateWeddingCashGift.pending, (state) => {
      state.pageLoading = false;
      state.bottonLoading = true;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(updateWeddingCashGift.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateWeddingCashGift.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(getSelectedGift.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.emptyState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getSelectedGift.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = "";

      state.weddingSelectedGifts = action.payload.data;

      if (state.weddingSelectedGifts.length === 0) {
        state.emptyState = true;
      }

      state.pageLoading = false;
    });
    builder.addCase(getSelectedGift.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(updateSelectedGift.pending, (state) => {
      state.pageLoading = false;
      state.bottonLoading = true;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(updateSelectedGift.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateSelectedGift.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(removeSelectedGift.pending, (state) => {
      state.emptyState = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
      state.pageLoading = true;
    });
    builder.addCase(removeSelectedGift.fulfilled, (state, action) => {
      state.pageLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(removeSelectedGift.rejected, (state, action) => {
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetGiftState, removeSelectedGiftFromState } =
  giftSlice.actions;
export default giftSlice.reducer;
