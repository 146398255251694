import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getWeddingPictures = createAsyncThunk(
  "picture/getWeddingPictures",
  async () => {
    const response = await axiosInstance.get(`users/wedding-pictures`);
    const data = await response.data;
    return data;
  }
);

export const updateWeddingPictures = createAsyncThunk(
  "picture/updateWeddingPictures",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `users/wedding-pictures`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
