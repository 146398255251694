import React from "react";
import FeaturedVenues from "../components/FeaturedVenues";

const SearchWeddingVenue = () => {
  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh] pb-24 overflow-x-hidden">
          <div className="text-center mb-5">
            <h3 className="text-2xl md:text-4xl  text-black  font-black">Search For Wedding Venues</h3>
          </div>

          <div className="max-w-full w-[700px] mx-auto h-12 sm:h-14 rounded-xl sm:rounded-[20px] flex justify-between items-center">
            <div className="w-[550px] max-w-full h-12 sm:h-14 max-h-full border-r-0  border border-jr_grey rounded-[12px_0_0_12px] sm:rounded-[20px_0_0_20px] ">
              <input
                type="search"
                name=""
                id=""
                className="h-12 sm:h-14 max-h-full w-full px-6 rounded-[12px_0_0_12px] sm:rounded-[20px_0_0_20px] truncate text-xs sm:text-sm md:text-base"
                placeholder="Enter the location"
              />
            </div>
            <button className="w-[150px] max-w-full h-12 sm:h-14 max-h-full bg-jr_pink border border-transparent cursor-pointer flex justify-center items-center text-white text-center rounded-[0_12px_12px_0] sm:rounded-[0_20px_20px_0] py-4 px-4 sm:px-8 relative">
              <span className="text-sm sm:text-base font-semibold w-full">Search</span>
            </button>
          </div>

          <div className="py-12">
            <div className="text-left mb-5">
              <h3 className="text-2xl md:text-4xl  text-jr_pink  font-black">Featured Venues</h3>
            </div>
            <div className="grid gap-5 my-6 sm:grid-cols-2 lg:grid-cols-3">
              <FeaturedVenues />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchWeddingVenue;
