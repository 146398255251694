import React from "react";
import { VscChromeClose } from "react-icons/vsc";
import PropTypes from "prop-types";
import ButtonLoader from "../ButtonLoader";

const ConfirmModal = ({
  title,
  message,
  closeModal,
  handleSubmit,
  bottonLoading,
  buttonText = "Proceed",
  showButton = true,
  showSuccessImage = false,
}) => {
  return (
    <section>
      <div className="bg-[#00000087] z-50 flex flex-row flex-wrap h-full justify-center items-center fixed inset-0 w-full overflow-auto transition-all duration-300 ease-out">
        <div className="max-w-[500px] w-full py-24 p-3 m-0">
          <div className="cursor-pointer flex justify-end items-center  mb-3">
            <button className="bg-white w-8 h-8 rounded-full flex justify-center items-center">
              <VscChromeClose color="#f9508c" size={20} onClick={closeModal} />
            </button>
          </div>
          <div className="bg-white rounded-xl p-4 sm:p-9 shadow-[0_2px_8px_#0003]">
            <div>
              {title && (
                <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-6">
                  <h3 className="text-lg md:text-xl font-black text-fa_orange">
                    {title}
                  </h3>
                </div>
              )}

              {showSuccessImage && (
                <div className="flex justify-center items-center flex-wrap sm:flex-nowrap gap-6">
                  <img
                    src="./assets/images/success.png"
                    alt="Empty"
                    className="h-[50px]"
                  />
                </div>
              )}

              <div className="pt-6">
                <div className="grid gap-5 sm:gap-10">
                  <div className="grid gap-5">
                    <p className="text-sm sm:text-base  font-bold text-black text-center">
                      {message}
                    </p>
                  </div>
                </div>

                {showButton && (
                  <div className="pt-6">
                    {bottonLoading ? (
                      <button
                        className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                        disabled
                      >
                        <ButtonLoader />
                      </button>
                    ) : (
                      <button
                        className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                        onClick={() => handleSubmit()}
                      >
                        <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                          {buttonText}
                        </span>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  closeModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  bottonLoading: PropTypes.bool,
  showButton: PropTypes.bool,
  showSuccessImage: PropTypes.bool,
};

export default ConfirmModal;
