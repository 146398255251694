import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getWeddingStories,
  updateWeddingStories,
} from "../store/actions/story";
import { resetStoryState } from "../store/slices/story";
import ButtonLoader from "../components/ButtonLoader";
import PageLoader from "../components/PageLoader";

const LoveStory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    groomStory: "",
    brideStory: "",
  });
  const bottonLoading = useSelector((state) => state.story.bottonLoading);
  const pageLoading = useSelector((state) => state.story.pageLoading);
  const errorMessage = useSelector((state) => state.story.errorMessage);
  const successMessage = useSelector((state) => state.story.successMessage);
  const weddingStories = useSelector((state) => state.story.weddingStories);

  useEffect(() => {
    dispatch(getWeddingStories());

    return () => {
      dispatch(resetStoryState());
    };
  }, []);

  useEffect(() => {
    setFormDetails({
      ...formDetails,
      groomStory: weddingStories?.groom_story || "",
      brideStory: weddingStories?.bride_story || "",
    });
  }, [weddingStories]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      navigate("/wedding-registry");
    }
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    if (
      formDetails.groomStory.trim() === "" ||
      formDetails.brideStory.trim() === ""
    ) {
      toast.error("All fields are required to proceed.");
      return;
    }

    const payload = {
      groom_story: formDetails.groomStory.trim(),
      bride_story: formDetails.brideStory.trim(),
    };

    dispatch(updateWeddingStories(payload));
  };

  return (
    <section className="pt-12">
      {pageLoading && <PageLoader />}

      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Love Story - Step 2
                </h3>
                <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                  Fill the form below to continue.
                </p>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="mb-12">
              <h3 className="text-xl sm:text-2xl text-jr_pink font-semibold mb-3 ">
                Add your love story here:
              </h3>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                Tell us how you met, tell us everything:
              </h6>

              <textarea
                placeholder="type here; tell us everything... (for the groom)"
                className="bg-transparent text-black text-sm  font-semibold h-[300px] p-6 w-full relative border border-jr_grey mt-3  max-w-[900px]"
                value={formDetails.groomStory}
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    groomStory: e.target.value,
                  })
                }
              ></textarea>

              <textarea
                placeholder="type here; tell us everything... (for the bride)"
                className="bg-transparent text-black text-sm  font-semibold h-[300px] p-6 w-full relative border border-jr_grey mt-3  max-w-[900px]"
                value={formDetails.brideStory}
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    brideStory: e.target.value,
                  })
                }
              ></textarea>
            </div>

            <div className="mt-12  max-w-[350px]">
              <div>
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Save & continue
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoveStory;
