import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    console.log("Making request to " + config.url);
    // if (!navigator.onLine) {
    //   alert("Please check your Internet Connection");
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      console.log(error.response.status);
      if (location.pathname !== "/") {
        localStorage.setItem("session_expired", "true");
        location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
