import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getWeddingCashGift,
  updateWeddingCashGift,
} from "../store/actions/gift";
import { resetGiftState } from "../store/slices/gift";
import ButtonLoader from "../components/ButtonLoader";
import PageLoader from "../components/PageLoader";

const CashGifts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    groomStory: "",
    brideStory: "",
  });
  const bottonLoading = useSelector((state) => state.gift.bottonLoading);
  const pageLoading = useSelector((state) => state.gift.pageLoading);
  const errorMessage = useSelector((state) => state.gift.errorMessage);
  const successMessage = useSelector((state) => state.gift.successMessage);
  const weddingCashGifts = useSelector((state) => state.gift.weddingCashGifts);

  useEffect(() => {
    dispatch(getWeddingCashGift());

    return () => {
      dispatch(resetGiftState());
    };
  }, []);

  useEffect(() => {
    setFormDetails({
      ...formDetails,
      cashGift: weddingCashGifts?.cash_gift || "",
      groupGift: weddingCashGifts?.group_gift || "",
    });
  }, [weddingCashGifts]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      navigate("/wedding-registry");
    }
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    if (formDetails.cashGift === "" || formDetails.groupGift === "") {
      toast.error("All fields are required to proceed.");
      return;
    }

    const payload = {
      cash_gift: formDetails.cashGift.trim(),
      group_gift: formDetails.groupGift.trim(),
    };

    dispatch(updateWeddingCashGift(payload));
  };

  return (
    <section className="pt-12">
      {pageLoading && <PageLoader />}

      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Cash Gifts - Step 5
                </h3>
                <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                  Select from the form below to continue.
                </p>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                1. Would You Like To Accept Cash Gifts?
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[350px]">
                <select
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  value={formDetails.cashGift}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      cashGift: e.target.value,
                    })
                  }
                >
                  <option value="">Yes or No</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
              </div>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                2. Would You Like To Allow Group Gifting?
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[350px]">
                <select
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  value={formDetails.groupGift}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      groupGift: e.target.value,
                    })
                  }
                >
                  <option value="">Yes or No</option>
                  <option value="YES">Yes</option>
                  <option value="NO">No</option>
                </select>
              </div>
            </div>

            <div className="mt-12  max-w-[350px]">
              <div>
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Save & continue
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CashGifts;
