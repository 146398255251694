import { createSlice } from "@reduxjs/toolkit";
import { verifyEmail } from "../actions/email";

const initialState = {
  bottonLoading: false,
  errorMessage: "",
  successMessage: "",
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    resetEmailState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyEmail.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export const { resetEmailState } = emailSlice.actions;
export default emailSlice.reducer;
