import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateBank, getBank } from "../store/actions/bank";
import { resetBankState } from "../store/slices/bank";
import ButtonLoader from "../components/ButtonLoader";
import PageLoader from "../components/PageLoader";

const BankDetails = () => {
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    bankName: "",
    accountName: "",
    accountNumber: "",
  });

  const bankDetails = useSelector((state) => state.bank.bankDetails);
  const bottonLoading = useSelector((state) => state.bank.bottonLoading);
  const pageLoading = useSelector((state) => state.bank.pageLoading);
  const errorMessage = useSelector((state) => state.bank.errorMessage);
  const successMessage = useSelector((state) => state.bank.successMessage);

  useEffect(() => {
    dispatch(getBank());
  }, []);

  useEffect(() => {
    if (bankDetails) {
      setFormDetails({
        ...formDetails,
        bankName: bankDetails?.bank_name || "",
        accountName: bankDetails?.account_name || "",
        accountNumber: bankDetails?.account_number || "",
      });
    }
  }, [bankDetails]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
    }

    return () => {
      dispatch(resetBankState());
    };
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    const validateData =
      formDetails.bankName.trim() !== "" &&
      formDetails.accountName.trim() !== "" &&
      formDetails.accountNumber.trim() !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    if (formDetails.accountNumber.trim().length !== 10) {
      toast.error("Account number should be 10 digits.");
      return;
    }

    let payload = {
      bank_name: formDetails.bankName.trim(),
      account_name: formDetails.accountName.trim(),
      account_number: formDetails.accountNumber.trim(),
    };

    dispatch(updateBank(payload));
  };

  return (
    <div>
      {pageLoading && <PageLoader />}

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Bank Name
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="text"
            placeholder="Enter bank name"
            value={formDetails.bankName}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                bankName: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Account Name
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="text"
            placeholder="Enter account name"
            value={formDetails.accountName}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                accountName: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Account Number
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type="text"
            placeholder="Enter account number"
            value={formDetails.accountNumber}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                accountNumber: e.target.value.replace(/\D/g, ""),
              })
            }
          />
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-auto sm:ml-auto sm:mr-0 mt-12">
          {bottonLoading ? (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              disabled
            >
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              onClick={submitForm}
            >
              <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                Save Bank Details
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
