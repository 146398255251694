import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";

export const getOverview = createAsyncThunk(
  "overview/getOverview",
  async () => {
    const response = await axiosInstance.get(`users/overview`);
    const data = await response.data;
    return data;
  }
);
