import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackButton from "../components/BackButton";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getWeddingDetails,
  updateWeddingDetails,
} from "../store/actions/wedding";
import { resetWeddingState } from "../store/slices/wedding";
import ButtonLoader from "../components/ButtonLoader";
import PageLoader from "../components/PageLoader";
import moment from "moment";

const WeddingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    eventIdentifier: "",
    eventBrideName: "",
    eventGroomName: "",
    eventMessage: "",
    eventVenue: "",
    eventHour: "",
    eventMinute: "",
    eventPeriod: "",
  });
  const bottonLoading = useSelector((state) => state.wedding.bottonLoading);
  const pageLoading = useSelector((state) => state.wedding.pageLoading);
  const errorMessage = useSelector((state) => state.wedding.errorMessage);
  const successMessage = useSelector((state) => state.wedding.successMessage);
  const weddingDetails = useSelector((state) => state.wedding.weddingDetails);

  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    dispatch(getWeddingDetails());

    return () => {
      dispatch(resetWeddingState());
    };
  }, []);

  useEffect(() => {
    setFormDetails({
      ...formDetails,
      eventIdentifier: weddingDetails?.hash_tag || "",
      eventBrideName: weddingDetails?.bride_name || "",
      eventGroomName: weddingDetails?.groom_name || "",
      eventMessage: weddingDetails?.description || "",
      eventVenue: weddingDetails?.event_venue || "",
      eventHour: weddingDetails?.event_hour || "",
      eventMinute: weddingDetails?.event_minute || "",
      eventPeriod: weddingDetails?.event_period || "",
    });

    if (weddingDetails?.event_date) {
      console.log(weddingDetails?.event_date);
      const newTime = new Date(weddingDetails.event_date);
      if (newTime.toString() !== "Invalid Date") {
        setSelectedDate(newTime);
      }
    }
  }, [weddingDetails]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      navigate("/wedding-registry");
    }
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    if (formDetails.eventIdentifier.trim() === "") {
      toast.error("Please enter your wedding hashtag.");
      return;
    }

    if (formDetails.eventIdentifier.trim().includes("#")) {
      toast.error("Your hash tag should not have an hash (#) sign.");
      return;
    }

    if (formDetails.eventBrideName.trim() === "") {
      toast.error("Please enter bride's full name.");
      return;
    }

    if (formDetails.eventGroomName.trim() === "") {
      toast.error("Please enter groom's full name.");
      return;
    }

    if (formDetails.eventMessage.trim() === "") {
      toast.error("Please enter your welcome message for your guest.");
      return;
    }

    const payload = {
      hash_tag: formDetails.eventIdentifier.trim(),
      bride_name: formDetails.eventBrideName.trim(),
      groom_name: formDetails.eventGroomName.trim(),
      message: formDetails.eventMessage.trim(),
      event_date: moment(selectedDate).format("MM-DD-YYYY"),
      event_venue: formDetails.eventVenue.trim(),
      event_hour: formDetails.eventHour,
      event_minute: formDetails.eventMinute,
      event_period: formDetails.eventPeriod,
    };

    dispatch(updateWeddingDetails(payload));
  };

  return (
    <section className="pt-12">
      {pageLoading && <PageLoader />}

      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Wedding Details - Step 1
                </h3>
                <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                  Fill the form below to continue.
                </p>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="mb-12">
              <h3 className="text-xl sm:text-2xl text-jr_pink font-semibold mb-3 ">
                Tell Us About Your Wedding
              </h3>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                What’s your wedding hashtag?
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <input
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  type="text"
                  placeholder=" e.g. AyoJada2023"
                  value={formDetails.eventIdentifier}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      eventIdentifier: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                Bride&apos;s Full Name
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <input
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  type="text"
                  placeholder="e.g. Thank you for visiting our wedding website. "
                  value={formDetails.eventBrideName}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      eventBrideName: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                Groom&apos;s Full Name
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <input
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  type="text"
                  placeholder="e.g. Thank you for visiting our wedding website. "
                  value={formDetails.eventGroomName}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      eventGroomName: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                Would you like to add a welcome message?
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <input
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  type="text"
                  placeholder="e.g. Thank you for visiting our wedding website. "
                  value={formDetails.eventMessage}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      eventMessage: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                Add more wedding information (Date, Venue and Time of Event):{" "}
              </h6>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <div className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full flex items-center">
                  <DatePicker
                    dateFormat="dd-MMMM-yyyy"
                    minDate={new Date()}
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    className="w-full"
                  />
                  <FaCalendarAlt size={20} />
                </div>
              </div>
              <div className="relative border border-jr_grey mt-3 max-w-[600px]">
                <input
                  className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                  type="text"
                  placeholder="Wedding venue"
                  value={formDetails.eventVenue}
                  onChange={(e) =>
                    setFormDetails({
                      ...formDetails,
                      eventVenue: e.target.value,
                    })
                  }
                />
              </div>
              <div className="grid md:grid-cols-3 gap-3 md:gap-8 mt-3 max-w-[600px]">
                <div className="relative border border-jr_grey ">
                  <select
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    value={formDetails.eventHour}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        eventHour: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select Hour</option>
                    <option value={"01"}>01</option>
                    <option value={"02"}>02</option>
                    <option value={"03"}>03</option>
                    <option value={"04"}>04</option>
                    <option value={"05"}>05</option>
                    <option value={"06"}>06</option>
                    <option value={"07"}>07</option>
                    <option value={"08"}>08</option>
                    <option value={"09"}>09</option>
                    <option value={"10"}>10</option>
                    <option value={"11"}>11</option>
                    <option value={"12"}>12</option>
                  </select>
                </div>
                <div className="relative border border-jr_grey ">
                  <select
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    value={formDetails.eventMinute}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        eventMinute: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select Minute</option>
                    <option value={"00"}>00</option>
                    <option value={"05"}>05</option>
                    <option value={"10"}>10</option>
                    <option value={"15"}>15</option>
                    <option value={"20"}>20</option>
                    <option value={"25"}>25</option>
                    <option value={"30"}>30</option>
                    <option value={"35"}>35</option>
                    <option value={"40"}>40</option>
                    <option value={"45"}>45</option>
                    <option value={"50"}>50</option>
                    <option value={"55"}>55</option>
                  </select>
                </div>
                <div className="relative border border-jr_grey ">
                  <select
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    value={formDetails.eventPeriod}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        eventPeriod: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select Period</option>
                    <option value={"AM"}>AM</option>
                    <option value={"PM"}>PM</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-12  max-w-[350px]">
              <div>
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Save & continue
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeddingDetails;
