import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async () => {
    const response = await axiosInstance.get(`users/gifts/categories`);
    const data = await response.data;
    return data;
  }
);

export const getGiftCategories = createAsyncThunk(
  "category/getGiftCategories",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `users/categories/gifts?search=${payload.search}&category=${payload.category}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const addSelectedGift = createAsyncThunk(
  "category/addSelectedGift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `users/categories/gifts/${payload}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

// addSelectedGift
