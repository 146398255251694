import React, { useEffect } from "react";
import BackButton from "../components/BackButton";
import SelectedGiftsTable from "../components/Tables/SelectedGiftsTable";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getSelectedGift, removeSelectedGift } from "../store/actions/gift";
import {
  resetGiftState,
  removeSelectedGiftFromState,
} from "../store/slices/gift";
import PageLoader from "../components/PageLoader";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";

const SelectedGifts = () => {
  const dispatch = useDispatch();

  const pageLoading = useSelector((state) => state.gift.pageLoading);
  const errorMessage = useSelector((state) => state.gift.errorMessage);
  const weddingSelectedGifts = useSelector(
    (state) => state.gift.weddingSelectedGifts
  );
  const emptyState = useSelector((state) => state.gift.emptyState);
  const errorState = useSelector((state) => state.gift.errorState);
  const successMessage = useSelector((state) => state.gift.successMessage);

  useEffect(() => {
    dispatch(getSelectedGift());

    return () => {
      dispatch(resetGiftState());
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
    }
  }, [successMessage]);

  const removeGift = async (val) => {
    toast.dismiss();

    dispatch(removeSelectedGiftFromState(val.id));
    dispatch(removeSelectedGift(val.id));
  };

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> View My List
                </h3>
              </div>
            </div>
          </div>

          {pageLoading && <PageLoader />}
          {!pageLoading && emptyState && (
            <div>
              <NoRecordFound title="Oops! no record available at the moment. " />
            </div>
          )}
          {!pageLoading && errorState && (
            <div>
              <ErrorFound title={errorMessage} />
            </div>
          )}

          {weddingSelectedGifts.length > 0 && (
            <div className="py-12">
              <SelectedGiftsTable
                data={weddingSelectedGifts}
                removeGift={removeGift}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SelectedGifts;
