import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getWeddingStories = createAsyncThunk(
  "story/getWeddingStories",
  async () => {
    const response = await axiosInstance.get(`users/wedding-stories`);
    const data = await response.data;
    return data;
  }
);

export const updateWeddingStories = createAsyncThunk(
  "story/updateWeddingStories",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `users/wedding-stories`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
