import React, { useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getWeddingPictures,
  updateWeddingPictures,
} from "../store/actions/picture";
import { resetPictureState } from "../store/slices/picture";
import ButtonLoader from "../components/ButtonLoader";
import PageLoader from "../components/PageLoader";

const WeddingPictures = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bottonLoading = useSelector((state) => state.picture.bottonLoading);
  const pageLoading = useSelector((state) => state.picture.pageLoading);
  const errorMessage = useSelector((state) => state.picture.errorMessage);
  const successMessage = useSelector((state) => state.picture.successMessage);
  const weddingPictures = useSelector((state) => state.picture.weddingPictures);

  const [pictureList, setPictureList] = useState([
    {
      id: new Date().getTime(),
      fileUpload: null,
      fileName: "",
      previewImg: "",
      imageLink: "",
    },
  ]);

  useEffect(() => {
    dispatch(getWeddingPictures());

    return () => {
      dispatch(resetPictureState());
    };
  }, []);

  useEffect(() => {
    if (weddingPictures) {
      if (weddingPictures.length > 0) {
        const newPictures = [];
        weddingPictures.forEach((item) => {
          newPictures.push({
            id: item.id,
            fileUpload: item.image,
            fileName: item.image,
            previewImg: item.image,
            imageLink: item.image,
          });
        });
        setPictureList(newPictures);
      }
    }
  }, [weddingPictures]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      navigate("/wedding-registry");
    }
  }, [errorMessage, successMessage]);

  const addPicture = () => {
    setPictureList([
      ...pictureList,
      {
        id: new Date().getTime(),
        fileUpload: null,
        fileName: "",
        previewImg: "",
        imageLink: "",
      },
    ]);
  };

  const removePicture = (id) => {
    const newPicture = pictureList.filter((item) => item.id !== id);
    setPictureList(newPicture);
  };

  const getPicture = (data, event) => {
    toast.dismiss();

    const newPicture = pictureList.find((item) => item.id === data.id);

    if (newPicture) {
      const target = event.target;
      const file = target.files[0];
      newPicture.fileUpload = null;
      newPicture.fileName = "";
      newPicture.previewImg = "";

      if (file) {
        const ImgExt = file.name.split(".").pop();

        if (
          ImgExt.toLowerCase() !== "png" &&
          ImgExt.toLowerCase() !== "jpg" &&
          ImgExt.toLowerCase() !== "jpeg"
        ) {
          newPicture.fileUpload = null;
          newPicture.fileName = "";
          newPicture.previewImg = "";
          toast.error("Uploaded file must be png, jpg, jpeg or pdf.");
          return;
        }
        if (file.size > 1000000) {
          newPicture.fileUpload = null;
          newPicture.fileName = "";
          newPicture.previewImg = "";
          toast.error("Uploaded file must be png, jpg, jpeg or pdf.");
          return;
        }

        newPicture.fileName = file.name;
        newPicture.fileUpload = file;
        newPicture.previewImg = URL.createObjectURL(file);
      }

      const newPictureList = pictureList.filter((item) => item.id !== data.id);
      setPictureList([...newPictureList, newPicture]);
    }
  };

  const submitForm = async () => {
    toast.dismiss();

    if (!pictureList[0].fileUpload) {
      toast.error("Please add your wedding picture(s).");
      return;
    }

    const payload = new FormData();

    pictureList.forEach((item) => {
      if (item.fileUpload && item.imageLink) {
        payload.append(`image_link[]`, item.imageLink);
      } else if (item.fileUpload && item.imageLink === "") {
        payload.append(`image_upload[]`, item.fileUpload);
      }
    });

    dispatch(updateWeddingPictures(payload));
  };

  return (
    <section className="pt-12">
      {pageLoading && <PageLoader />}

      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Wedding Pictures - Step 3
                </h3>
                <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                  Fill the form below to continue.
                </p>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="mb-12">
              <h3 className="text-xl sm:text-2xl text-jr_pink font-semibold mb-3 ">
                Add wedding pictures:
              </h3>
            </div>

            <div className="mb-12">
              <h6 className="text-sm sm:text-base  font-bold text-black">
                PS each uploaded images should not be more than 1mb
              </h6>

              {pictureList.map((item) => (
                <div
                  key={item.id}
                  className="relative border border-jr_grey mt-3 max-w-[600px]"
                >
                  {item.imageLink === "" && (
                    <div className="flex relative justify-center items-center flex-col  cursor-pointer">
                      <div className="flex flex-col relative">
                        {!item.previewImg && (
                          <img
                            className="w-1/3 mx-auto mt-12"
                            src="https://res.cloudinary.com/dndntw861/image/upload/v1682934790/JoyRibbons/image_syt6n4.png"
                            alt="joyribbons"
                          />
                        )}

                        {!item.previewImg && (
                          <p className="w-full text-center text-sm sm:text-base text-jr_slateGray font-medium mb-12">
                            click to select and add images
                          </p>
                        )}

                        {item.previewImg && (
                          <img
                            className="mx-auto"
                            src={item.previewImg}
                            alt="joyribbons"
                          />
                        )}

                        <input
                          type="file"
                          onChange={(event) => getPicture(item, event)}
                          className="absolute inset-0 opacity-0 "
                        />
                      </div>
                    </div>
                  )}

                  {item.imageLink !== "" && (
                    <div className="flex relative justify-center items-center flex-col  cursor-pointer">
                      <img
                        className="mx-auto w-full"
                        src={item.imageLink}
                        alt="joyribbons"
                      />
                    </div>
                  )}

                  {item.fileName !== "" && pictureList.length > 1 && (
                    <p
                      className="text-red-600 text-sm my-2 cursor-pointer   w-[120px] text-right font-bold"
                      onClick={() => removePicture(item.id)}
                    >
                      click to remove
                    </p>
                  )}
                </div>
              ))}

              <h4 className="flex flex-wrap items-center justify-end mt-4 max-w-[600px]">
                <button
                  className=" font-bold w-[150px] text-sm text-black"
                  onClick={addPicture}
                >
                  + click to add more
                </button>
              </h4>
            </div>

            <div className="mt-12  max-w-[350px]">
              <div>
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink  w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Save & continue
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeddingPictures;
