import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";

export const getRegistryDetails = createAsyncThunk(
  "registry/getRegistryDetails",
  async () => {
    const response = await axiosInstance.get(`users/registries`);
    const data = await response.data;
    return data;
  }
);

export const createRegistry = createAsyncThunk(
  "registry/createRegistry",
  async () => {
    const response = await axiosInstance.get(`users/create_registries`);
    const data = await response.data;
    return data;
  }
);
