import React, { useState, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { resetRegisterState } from "../store/slices/register";
import { registerUser } from "../store/actions/register";
import ButtonLoader from "../components/ButtonLoader";

const Register = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [openPassword, setOpenPassword] = useState(<BsFillEyeFill size={18} />);
  const [openConfirmPassword, setOpenConfirmPassword] = useState(
    <BsFillEyeFill size={18} />
  );

  const dispatch = useDispatch();
  const bottonLoading = useSelector((state) => state.register.bottonLoading);
  const errorMessage = useSelector((state) => state.register.errorMessage);
  const successMessage = useSelector((state) => state.register.successMessage);
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    aboutUs: "",
    password: "",
    confirmPassword: "",
  });

  const showPasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setOpenPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setPasswordType("password");
      setOpenPassword(<BsFillEyeFill size={18} />);
    }
  };

  const showConfirmPasswordType = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      setOpenConfirmPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setConfirmPasswordType("password");
      setOpenConfirmPassword(<BsFillEyeFill size={18} />);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
      setFormDetails({
        ...formDetails,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        aboutUs: "",
        password: "",
        confirmPassword: "",
      });

      navigate(`/verify-email`);
    }

    return () => {
      dispatch(resetRegisterState());
    };
  }, [errorMessage, successMessage]);

  const submitForm = () => {
    toast.dismiss();

    const validateData =
      formDetails.firstName.trim() !== "" &&
      formDetails.lastName.trim() !== "" &&
      formDetails.email.trim() !== "" &&
      formDetails.phone.trim() !== "" &&
      formDetails.aboutUs.trim() !== "" &&
      formDetails.password.trim() !== "" &&
      formDetails.confirmPassword.trim() !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    if (/\S+@\S+\.\S+/g.test(formDetails.email.trim()) === false) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (/^[0-9]+$/g.test(formDetails.phone.trim()) === false) {
      toast.error("Please enter a valid phone number.");
      return;
    }

    if (formDetails.password.trim() !== formDetails.confirmPassword.trim()) {
      toast.error("new password and confirm password are different.");
      return;
    }

    const payload = {
      first_name: formDetails.firstName.trim(),
      last_name: formDetails.lastName.trim(),
      email: formDetails.email.trim(),
      phone: formDetails.phone.trim(),
      about_us: formDetails.aboutUs.trim(),
      password: formDetails.password.trim(),
    };

    dispatch(registerUser(payload));
  };

  return (
    <section className="grid lg:grid-cols-2">
      <div className="h-full overflow-auto  py-12 z-50 w-full">
        <div className="container">
          <div className="sm:w-9/12 mx-auto hidden lg:block">
            <div className="mx-auto lg:mx-0 w-48">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-9/12 sm:mx-auto mt-10 lg:mt-20">
            <div className="mb-6 w-36 block lg:hidden">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-6">
              <h3 className="text-2xl md:text-5xl text-black font-black">
                Welcome.
              </h3>
              <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                Let&lsquo;s get you started.
              </p>
            </div>

            <div className="flex flex-col gap-5">
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  First Name
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="text"
                    placeholder="Enter your first name"
                    value={formDetails.firstName}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Last Name
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="text"
                    placeholder="Enter your last name"
                    value={formDetails.lastName}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Email
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="email"
                    placeholder="Enter your email"
                    value={formDetails.email}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Phone Number
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="tel"
                    placeholder="Enter your phone number"
                    value={formDetails.phone}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        phone: e.target.value.replace(/\D/g, ""),
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  {" "}
                  How Did You Hear About Us?
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="text"
                    placeholder="Enter  how did you heard about us"
                    value={formDetails.aboutUs}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        aboutUs: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex items-center flex-col gap-6 sm:gap-0 sm:flex-row flex-nowrap justify-between content-center">
                <div className="w-full sm:w-[48%]">
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Enter Password
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <input
                      className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                      type={passwordType}
                      placeholder="create password"
                      value={formDetails.password}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          password: e.target.value,
                        })
                      }
                    />
                    <div
                      onClick={showPasswordType}
                      className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
                    >
                      {openPassword}
                    </div>
                  </div>
                </div>

                <div className="w-full sm:w-[48%]">
                  <label className="text-sm sm:text-base  font-bold text-black">
                    Confirm Password
                  </label>
                  <div className="relative border border-jr_grey mt-3">
                    <input
                      className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                      type={confirmPasswordType}
                      placeholder="confirm password"
                      value={formDetails.confirmPassword}
                      onChange={(e) =>
                        setFormDetails({
                          ...formDetails,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                    <div
                      onClick={showConfirmPasswordType}
                      className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
                    >
                      {openConfirmPassword}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      {" "}
                      Signup
                    </span>
                  </button>
                )}
              </div>
              <p className="text-jr_slateGray text-sm font-normal sm:text-base text-center">
                {" "}
                Already registered?{" "}
                <Link
                  to="/"
                  className="text-sm text-jr_pink font-bold sm:text-base"
                >
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" z-50  hidden lg:block bg-cover bg-no-repeat bg-[50%] bg-jr_auth fixed right-0 top-0 w-1/2 h-full ">
        <div className="bg-[#00000080] h-full  pt-12 pr-12">
          <div className="w-48 ml-auto">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/76e1df154768775.6365a98fb0df9.png"
              alt="joyribbons"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
