import React, { useEffect, useState } from "react";
import MobileBar from "./MobileBar";
import Navbar from "./Navbar";

const Header = () => {
  // to toggle the navbar and sidebar
  const [openMobileBar, setOpenMobileBar] = useState(false);

  const toggle = () => {
    setOpenMobileBar(!openMobileBar);
  };

  // the body underneath cant scroll
  useEffect(() => {
    function mobileBar() {
      if (window.innerWidth > 768) {
        setOpenMobileBar(true);
      } else {
        setOpenMobileBar(false);
      }
    }

    window.addEventListener("resize", mobileBar);

    return () => {
      window.removeEventListener("resize", mobileBar);
    };
  }, [openMobileBar]);
  return (
    <>
      <Navbar toggle={toggle} />
      <MobileBar open={openMobileBar} toggle={toggle} />
    </>
  );
};

export default Header;
