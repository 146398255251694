import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getWeddingLink = createAsyncThunk(
  "wedding/getWeddingLink",
  async () => {
    const response = await axiosInstance.get(`users/registry-links`);
    const data = await response.data;
    return data;
  }
);

export const getWeddingDetails = createAsyncThunk(
  "wedding/getWeddingDetails",
  async () => {
    const response = await axiosInstance.get(`users/wedding-details`);
    const data = await response.data;
    return data;
  }
);

export const updateWeddingDetails = createAsyncThunk(
  "wedding/updateWeddingDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `users/wedding-details`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
