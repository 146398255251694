import React, { useState, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useAuthState, useAuth } from "../context/AuthContext";
import { resetAuthState } from "../store/slices/auth";
import { loginUser } from "../store/actions/auth";
import ButtonLoader from "../components/ButtonLoader";

const Login = () => {
  const dispatch = useDispatch();
  const { onAuthSuccess, onAuthLogout } = useAuth();
  const { isAuth } = useAuthState();
  const [passwordType, setPasswordType] = useState("password");
  const [openPassword, setOpenPassword] = useState(<BsFillEyeFill size={18} />);
  const [formDetails, setFormDetails] = useState({
    email: "",
    password: "",
  });

  const bottonLoading = useSelector((state) => state.auth.bottonLoading);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const sessionExpired = localStorage.getItem("session_expired");
    if (sessionExpired && sessionExpired === "true") {
      toast.dismiss();

      toast.error("Session expired. Please login again");

      onAuthLogout();
      setTimeout(() => {
        localStorage.removeItem("session_expired");
      }, 200);
    }

    return () => {
      dispatch(resetAuthState());
    };
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();

      toast.error(errorMessage);
      onAuthLogout();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (user) {
      onAuthSuccess(user);
    }
  }, [user]);

  if (isAuth) {
    return <Navigate to="/overview" />;
  }

  const showPasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setOpenPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setPasswordType("password");
      setOpenPassword(<BsFillEyeFill size={18} />);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    toast.dismiss();

    const validateData =
      formDetails.password.trim() !== "" && formDetails.email.trim() !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    if (/\S+@\S+\.\S+/g.test(formDetails.email.trim()) === false) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const payload = {
      email: formDetails.email,
      password: formDetails.password,
    };

    dispatch(loginUser(payload));
  };

  return (
    <section className="grid lg:grid-cols-2">
      <div className="h-full overflow-auto  py-12 z-50 w-full">
        <div className="container">
          <div className="sm:w-9/12 mx-auto hidden lg:block">
            <div className="mx-auto lg:mx-0 w-48">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-9/12 sm:mx-auto mt-10 lg:mt-20">
            <div className="mb-6 w-36 block lg:hidden">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-6">
              {" "}
              <h3 className="text-2xl md:text-5xl text-black font-black">
                Welcome Back.
              </h3>
              <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                Please enter your login information.
              </p>
            </div>

            <div className="flex flex-col gap-5">
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Email
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="email"
                    placeholder="Enter your email"
                    value={formDetails.email}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Password
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type={passwordType}
                    placeholder="Enter your password"
                    value={formDetails.password}
                    onChange={(e) =>
                      setFormDetails({
                        ...formDetails,
                        password: e.target.value,
                      })
                    }
                  />
                  <div
                    onClick={showPasswordType}
                    className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
                  >
                    {openPassword}
                  </div>
                </div>
              </div>

              <p className="text-jr_slateGray text-sm font-normal sm:text-base sm:w-[calc(100%-30px)]">
                By signing up, you are agreeing to JoyRibbons&lsquo; terms of
                service condition
              </p>

              <p className="text-right">
                <Link
                  to="/forgot-password"
                  className="text-sm text-jr_pink font-bold sm:text-base"
                >
                  Forgot Password?
                </Link>
              </p>

              <div className="flex">
                {bottonLoading ? (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Login
                    </span>
                  </button>
                )}
              </div>
              <p className="text-jr_slateGray text-sm font-normal sm:text-base text-center">
                Don&lsquo;t have an account?{" "}
                <Link
                  to="/register"
                  className="text-sm text-jr_pink font-bold sm:text-base"
                >
                  Signup
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" z-50 hidden lg:block bg-cover bg-no-repeat bg-[50%] bg-jr_auth fixed right-0 top-0 w-1/2 h-full ">
        <div className="bg-[#00000080] h-full  pt-12 pr-12">
          <div className="w-48 ml-auto">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/76e1df154768775.6365a98fb0df9.png"
              alt="joyribbons"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
