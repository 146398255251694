import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GiftsModal from "../components/Modal/GiftsModal";
import { useSelector, useDispatch } from "react-redux";
import { getGiftCategories, addSelectedGift } from "../store/actions/category";
import {
  resetCategoryState,
  addSelectedGiftToState,
} from "../store/slices/category";
import PageLoader from "../components/PageLoader";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";
import { toThousandFormat } from "../Constant";
import { toast } from "react-toastify";

const GiftCenter = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const pageLoading = useSelector((state) => state.category.pageLoading);
  const errorMessage = useSelector((state) => state.category.errorMessage);
  const emptyState = useSelector((state) => state.category.emptyState);
  const errorState = useSelector((state) => state.category.errorState);
  const successMessage = useSelector((state) => state.category.successMessage);
  const giftCategoriesList = useSelector(
    (state) => state.category.giftCategoriesList
  );

  const [openModal, setOpenModal] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [selectedGift, setSelectedGift] = useState(null);

  useEffect(() => {
    if (id) {
      setCategory(id);
    }

    return () => {
      dispatch(resetCategoryState());
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);
    }
  }, [successMessage]);

  useEffect(() => {
    if (category) {
      handleData();
    }
  }, [category]);

  useEffect(() => {
    if (category) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
        setDebounceTimer(null);
      }

      const newDebounceTimer = setTimeout(() => {
        handleData();
      }, 1000);

      setDebounceTimer(newDebounceTimer);
    }
  }, [search]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "18px";
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = 0;
    }
  }, [openModal]);

  const handleData = () => {
    const payload = {
      category,
      search,
    };

    dispatch(getGiftCategories(payload));
  };

  const showModal = () => {
    setOpenModal(!openModal);
  };

  const viewGift = (item) => {
    setSelectedGift(item);
    showModal();
  };

  const addGift = (item) => {
    setOpenModal(false);
    dispatch(addSelectedGiftToState(item.id));
    dispatch(addSelectedGift(item.id));
  };

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <span
                    onClick={() => navigate(-1)}
                    className="cursor-pointer mr-3"
                  >
                    ←
                  </span>
                  {giftCategoriesList?.name || ""}
                </h3>
                <p className="text-sm sm:text-base text-jr_slateGray font-medium ">
                  Select any product you’d like to add to your registry by
                  clicking on the + icon
                </p>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-[1fr_0.5fr] gap-5 mb-8 sm:mb-16 mt-12">
            <div className="relative border border-jr_grey ">
              <input
                type="text"
                placeholder="Search Gifts"
                className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="relative border border-jr_grey ">
              <select
                className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="ALL">All Categories</option>
                {giftCategoriesList?.categories &&
                  giftCategoriesList.categories.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {pageLoading && <PageLoader />}
          {!pageLoading && emptyState && (
            <div>
              <NoRecordFound title="Oops! no record available at the moment. " />
            </div>
          )}
          {!pageLoading && errorState && (
            <div>
              <ErrorFound title={errorMessage} />
            </div>
          )}

          <div>
            <div className="grid gap-5 my-6 sm:grid-cols-2 lg:grid-cols-3">
              {giftCategoriesList?.gifts &&
                giftCategoriesList.gifts.map((item, id) => {
                  return (
                    <div key={id}>
                      <div
                        style={{ backgroundImage: `url(${item.image})` }}
                        className={` bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat`}
                      >
                        <div className="flex flex-col flex-wrap px-5 pt-12 relative justify-start items-center bg-[#0000004d] min-h-[350px]">
                          <h3 className="text-lg text-center sm:text-xl text-white font-bold">
                            {item.name}
                          </h3>
                          <p className="text-base pt-2 text-white font-semibold">
                            ₦{toThousandFormat(item.amount)}
                          </p>
                          <div className="absolute bottom-3 px-3 w-full">
                            <button
                              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-xs uppercase mt-3 flex-nowrap font-black h-12 justify-center text-white"
                              onClick={() => viewGift(item)}
                            >
                              View Product Details
                            </button>
                            <button
                              className="bg-white w-full flex items-center content-center cursor-pointer text-xs uppercase mt-3 flex-nowrap font-black h-12 justify-center"
                              onClick={() => addGift(item)}
                            >
                              Add to registry
                            </button>
                            {/* <button
                              style={{ color: "red" }}
                              className="bg-white w-full flex items-center content-center cursor-pointer text-xs uppercase mt-3 flex-nowrap font-black h-12 justify-center"
                              onClick={() => removeGift(item)}
                            >
                              Remove from registry
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {openModal && selectedGift && (
        <GiftsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          showModal={showModal}
          gift={selectedGift}
          category={giftCategoriesList?.name}
          addGift={addGift}
        />
      )}
    </section>
  );
};

export default GiftCenter;
