import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getMessages = createAsyncThunk(
  "message/getMessages",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `users/messages?page=${payload.pageIndex}&pageSize=${payload.pageSize}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
