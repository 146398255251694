import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import ErrorFound from "../components/ErrorFound";
import { useSelector, useDispatch } from "react-redux";
import { getOverview } from "../store/actions/overview";
import { resetOverviewState } from "../store/slices/overview";
import { toThousandFormat } from "../Constant";

const Analytics = () => {
  const dispatch = useDispatch();
  const pageLoading = useSelector((state) => state.overview.pageLoading);
  const analytics = useSelector((state) => state.overview.analytics);
  const errorState = useSelector((state) => state.overview.errorState);
  const errorMessage = useSelector((state) => state.overview.errorMessage);

  useEffect(() => {
    dispatch(getOverview());

    return () => {
      dispatch(resetOverviewState());
    };
  }, []);

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div>
              <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                Analytics
              </h3>
            </div>

            <div className="py-12">
              {pageLoading && <PageLoader />}

              {!pageLoading && errorState && (
                <div>
                  <ErrorFound title={errorMessage} />
                </div>
              )}

              <div className="grid gap-5 my-6 sm:grid-cols-2 lg:grid-cols-3">
                <Link
                  to="/analytics/rsvp"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden bg-jr_overviewWishList"
                >
                  <div className="bg-[#00000080] min-h-[300px] p-5 relative flex flex-col gap-5 items-center justify-center text-center">
                    <h3 className="text-base sm:text-lg md:text-xl text-white font-semibold">
                      Total RSVP
                    </h3>
                    <p className="text-xl sm:text-3xl truncate text-white font-semibold">
                      {analytics?.rsvp || "0"}
                    </p>
                    <button className="bg-white border border-white text-jr_pink  text-center w-3/4 sm:w-1/2 flex items-center justify-center cursor-pointer text-sm sm:text-base p-3  flex-nowrap font-black">
                      View More
                    </button>
                  </div>
                </Link>

                <Link
                  to="/analytics/messages"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden bg-jr_overviewWishList"
                >
                  <div className="bg-[#00000080] min-h-[300px] p-5 relative flex flex-col gap-5 items-center justify-center text-center">
                    <h3 className="text-base sm:text-lg md:text-xl text-white font-semibold">
                      Total Messages
                    </h3>
                    <p className="text-xl sm:text-3xl truncate text-white font-semibold">
                      {analytics?.messages || "0"}
                    </p>
                    <button className="bg-white border border-white text-jr_pink  text-center w-3/4 sm:w-1/2 flex items-center justify-center cursor-pointer text-sm sm:text-base p-3  flex-nowrap font-black">
                      View More
                    </button>
                  </div>
                </Link>

                <Link
                  to="/analytics/gifts-purchased"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden bg-jr_overviewMessages"
                >
                  <div className="bg-[#00000080] min-h-[300px] p-5 relative flex flex-col gap-5 items-center justify-center text-center">
                    <h3 className="text-base sm:text-lg md:text-xl text-white font-semibold">
                      Total Gifts Purchased
                    </h3>
                    <p className="text-xl sm:text-3xl truncate text-white font-semibold">
                      {analytics?.purchases || "0"}
                    </p>
                    <button className="bg-white border border-white text-jr_pink  text-center w-3/4 sm:w-1/2 flex items-center justify-center cursor-pointer text-sm sm:text-base p-3  flex-nowrap font-black">
                      View More
                    </button>
                  </div>
                </Link>

                <Link
                  to="/analytics/amount-purchased"
                  className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden bg-jr_overviewGifts"
                >
                  <div className="bg-[#00000080] min-h-[300px] p-5 relative flex flex-col gap-5 items-center justify-center text-center">
                    <h3 className="text-base sm:text-lg md:text-xl text-white font-semibold">
                      Total Amount From Purchased Gifts
                    </h3>
                    <p className="text-xl sm:text-3xl truncate text-white font-semibold">
                      ₦{toThousandFormat(analytics?.transactions)}
                    </p>
                    <button className="bg-white border border-white text-jr_pink  text-center w-3/4 sm:w-1/2 flex items-center justify-center cursor-pointer text-sm sm:text-base p-3  flex-nowrap font-black">
                      View More
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Analytics;
