import moment from "moment";

export const perPage = 50;
export const modalPerPage = 10;

export const toThousandFormat = (newValue) => {
  const value = parseFloat(newValue);
  if (value) {
    return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return "0.00";
};

export const errorMessage = (value) => {
  if (value) {
    return value;
  }
  return "Oops! Something went wrong.";
};

export const capitalize = (newValue) => {
  if (!newValue) return "";
  const value = newValue.toString();
  const arr = value.split(" ");
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(" ");
};

export const lowercase = (newValue) => {
  if (!newValue) return "";
  const value = newValue.toString();
  const strg = value.toLowerCase();
  return strg.charAt(0).toUpperCase() + strg.slice(1);
};

export const formatPercentage = (value) => {
  if (value && Number(value) !== 0) {
    if (+value) {
      return value;
    }
  }
  return 0;
};

export const formatInputCurrency = (newValue) => {
  if (newValue && Number(newValue) !== 0) {
    const value = newValue.replace(/,/g, "");
    if (+value) {
      if (value.includes(".")) {
        if (value.split(".")[1].length >= 0) {
          const valueBeforeComma = value.split(".")[0];
          const valueAfterComma = value.split(".")[1];
          let numBeforeComma;
          if (valueAfterComma.length <= 2) {
            numBeforeComma = value
              .split(".")[0]
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return `${numBeforeComma}.${valueAfterComma}`;
          }
          numBeforeComma = `${valueBeforeComma}${valueAfterComma.split("")[0]}`;
          const numAfterComma = `${valueAfterComma.split("")[1]}${
            valueAfterComma.split("")[2]
          }`;
          const numBeforeCommaFormatted = numBeforeComma
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          return `${numBeforeCommaFormatted}.${numAfterComma}`;
        }
      }
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
  return "";
};

export const handleTimestamp = (timestamp) => {
  try {
    if (typeof timestamp !== "number") {
      throw new Error("Invalid Timestamp");
    }

    if (`${timestamp}`.length > 12) {
      return new Date(timestamp / 1000000);
    }

    return new Date(timestamp * 1000);
  } catch {
    return new Date(0);
  }
};

export const toFriendlyDate = (value) => {
  if (value) {
    return moment(value).format("DD MMMM, YYYY");
  }
};
