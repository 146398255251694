import React, { useEffect } from "react";
import PageLoader from "../components/PageLoader";
import ErrorFound from "../components/ErrorFound";
import { useSelector, useDispatch } from "react-redux";
import { getWeddingLink } from "../store/actions/wedding";
import { resetWeddingState } from "../store/slices/wedding";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const RegistryLink = () => {
  const dispatch = useDispatch();
  const pageLoading = useSelector((state) => state.wedding.pageLoading);
  const weddingLink = useSelector((state) => state.wedding.weddingLink);
  const errorState = useSelector((state) => state.wedding.errorState);
  const errorMessage = useSelector((state) => state.wedding.errorMessage);

  useEffect(() => {
    dispatch(getWeddingLink());

    return () => {
      dispatch(resetWeddingState());
    };
  }, []);

  const handleCopy = () => {
    toast.dismiss();
    toast.success(
      `Link https://www.joyribbons.com/wedding/${weddingLink.hash_tag} copied.`
    );
  };

  return (
    <section className="pt-12">
      {pageLoading && <PageLoader />}

      {!pageLoading && errorState && (
        <div>
          <ErrorFound title={errorMessage} />
        </div>
      )}

      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          {weddingLink && (
            <div>
              <div>
                <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
                  <div className="lg:w-[calc(100%-400px)]">
                    <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                      Your Link is Ready
                    </h3>
                  </div>
                  <p className="flex items-center justify-end w-full md:w-[350px]">
                    <CopyToClipboard
                      text={`https://joyribbons.com/wedding/${weddingLink.hash_tag}`}
                      onCopy={handleCopy}
                    >
                      <button className="bg-jr_pink w-[150px] md:w-1/2 text-white flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center">
                        Copy my link
                      </button>
                    </CopyToClipboard>
                  </p>
                </div>
              </div>
              <div>
                <div className="flex justify-center items-center flex-col pt-12 text-center">
                  <div className="flex justify-center items-center mb-4">
                    <img
                      src="https://res.cloudinary.com/dndntw861/image/upload/v1682678124/JoyRibbons/congratulation_t2vsin.png"
                      alt="joyribbons"
                    />
                  </div>
                  <h3 className="text-base sm:text-lg text-black font-semibold mt-11 mb-3">
                    Your wishlist is ready
                  </h3>
                  <p className="text-sm sm:text-base text-jr_slateGray font-medium mb-12">
                    Someone from our team will reach out shortly on the progress
                    on this suggestion
                  </p>
                  <div className="flex w-[250px] flex-row flex-wrap justify-between items-center">
                    <span className="block w-10">
                      <a
                        href={`https://twitter.com/intent/tweet?text=https%3A//www.joyribbons.com/event/${weddingLink.hash_tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Twitter"
                      >
                        <img
                          src="https://app.joyribbons.com/_nuxt/twit.6ff175fd.png"
                          alt="twitter"
                        />
                      </a>
                    </span>
                    <span className="block w-10">
                      <a
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A//www.joyribbons.com/event/${weddingLink.hash_tag}&title=${weddingLink.event}&summary=${weddingLink.description}&source=https://www.joyribbons.com/event/${weddingLink.hash_tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on LinkedIn"
                      >
                        <img
                          src="https://app.joyribbons.com/_nuxt/link.2e388e90.svg"
                          alt="linkedin"
                        />
                      </a>
                    </span>
                    <span className="block w-10">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//www.joyribbons.com/event/${weddingLink.hash_tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Facebook"
                      >
                        <img
                          src="https://app.joyribbons.com/_nuxt/face.97807b83.png"
                          alt="facebook"
                        />
                      </a>
                    </span>
                    <span className="block w-10">
                      <a
                        href={`whatsapp://send?text=https%3A//www.joyribbons.com/event/${weddingLink.hash_tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on WhatsApp"
                      >
                        <img
                          src="https://app.joyribbons.com/_nuxt/what.249e411e.svg"
                          alt="whatsapp"
                        />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default RegistryLink;
