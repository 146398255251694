import React from "react";
import { Link, NavLink } from "react-router-dom";
import { VscChromeClose } from "react-icons/vsc";
import PropTypes from "prop-types";
import { useAuth } from "../context/AuthContext";
import { logOutUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

const MobileBar = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const { onAuthLogout } = useAuth();

  const navLinks = ({ isActive }) => {
    return isActive ? "active-navLink" : "navLink";
  };

  const logUserOut = () => {
    dispatch(logOutUser());
    toggle();
    onAuthLogout();
  };

  return (
    <section
      className={`fixed top-0 z-50 bg-white h-screen flex md:hidden w-full transition-all duration-500 ease-linear z- ${
        open ? "right-0" : "right-[100%]"
      } `}
    >
      <div className="container ">
        <div className="h-full">
          <div className="flex justify-between items-center w-full h-20">
            <div className="w-40 md:w-48">
              <Link to="/" className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </Link>
            </div>

            <div
              className="flex md:hidden cursor-pointer"
              onClick={toggle}
              open
            >
              <VscChromeClose color="#f9508c" size={24} />
            </div>
          </div>

          <div className="flex flex-col gap-5 h-full py-5">
            <div>
              <NavLink className={navLinks} to="/overview" onClick={toggle}>
                Overview
              </NavLink>
            </div>

            <div>
              <NavLink className={navLinks} to="/analytics" onClick={toggle}>
                Analytics
              </NavLink>
            </div>

            <div>
              <NavLink
                className={navLinks}
                to="/wedding-registry"
                onClick={toggle}
              >
                Website Details
              </NavLink>
            </div>

            <div>
              <NavLink
                className={navLinks}
                to="/registry-link"
                onClick={toggle}
              >
                My Wedding Link
              </NavLink>
            </div>

            {/* <div>
              <NavLink
                className={navLinks}
                to="/wedding-venue"
                onClick={toggle}
              >
                Search Wedding Venue
              </NavLink>
            </div> */}

            <div>
              <NavLink className={navLinks} to="/profile" onClick={toggle}>
                Profile
              </NavLink>
            </div>

            <div>
              <div
                className="text-base font-semibold lg:hover:underline relative text-jr_red"
                onClick={logUserOut}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

MobileBar.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default MobileBar;
