import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const getGiftsPurchased = createAsyncThunk(
  "gifts/getGiftsPurchased",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `users/gifts/purchases?page=${payload.pageIndex}&pageSize=${payload.pageSize}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const getWeddingCashGift = createAsyncThunk(
  "gift/getWeddingCashGift",
  async () => {
    const response = await axiosInstance.get(`users/wedding-cash-gifts`);
    const data = await response.data;
    return data;
  }
);

export const updateWeddingCashGift = createAsyncThunk(
  "gift/updateWeddingCashGift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `users/wedding-cash-gifts`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const getSelectedGift = createAsyncThunk(
  "gift/getSelectedGift",
  async () => {
    const response = await axiosInstance.get(`users/wedding-suggested-gifts`);
    const data = await response.data;
    return data;
  }
);

export const updateSelectedGift = createAsyncThunk(
  "gift/updateSelectedGift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `users/wedding-suggested-gifts`,
        payload
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const removeSelectedGift = createAsyncThunk(
  "gift/removeSelectedGift",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `users/wedding-suggested-gifts/${payload}`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
