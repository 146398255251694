import { createSlice } from "@reduxjs/toolkit";
import { getWeddingPictures, updateWeddingPictures } from "../actions/picture";

const initialState = {
  weddingPictures: null,
  pageLoading: false,
  bottonLoading: false,
  errorState: false,
  errorMessage: "",
  successMessage: "",
};

export const pictureSlice = createSlice({
  name: "picture",
  initialState,
  reducers: {
    resetPictureState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.errorState = false;
      state.pageLoading = false;
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWeddingPictures.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getWeddingPictures.fulfilled, (state, action) => {
      state.weddingPictures = action.payload.data;
      state.pageLoading = false;
    });
    builder.addCase(getWeddingPictures.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(updateWeddingPictures.pending, (state) => {
      state.pageLoading = false;
      state.bottonLoading = true;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(updateWeddingPictures.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateWeddingPictures.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetPictureState } = pictureSlice.actions;
export default pictureSlice.reducer;
