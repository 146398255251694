import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton";

const WeddingGifts = () => {
  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  <BackButton /> Gifts List - Step 4
                </h3>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-5">
              <Link to="/wedding-gifts/add-list">
                <div className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer">
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img src="/images/add-my-own-list.png" alt="joyribbons" className="h-8 w-8" />

                    <h3 className="text-base md:text-lg  text-jr_pink  font-black">Add My Own List</h3>
                  </div>
                </div>
              </Link>

              <Link to="/wedding-gifts/joyribbons-collection">
                <div className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer">
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img src="/images/select-gifts.png" alt="joyribbons" className="h-8 w-8" />

                    <h3 className="text-base md:text-lg  text-jr_pink  font-black">
                      Select Gifts from JoyRibbons Collection
                    </h3>
                  </div>
                </div>
              </Link>

              <Link to="/wedding-gifts/selected-gifts">
                <div className="bg-[50%] bg-no-repeat bg-cover rounded-md overflow-hidden  bg-white border-2 shadow-[1px_1px_3px_0px_#b9b9b975] cursor-pointer">
                  <div className="min-h-[200px] p-5 relative flex flex-col gap-5 justify-center items-center">
                    <img src="/images/view-all.png" alt="joyribbons" className="h-8 w-8" />

                    <h3 className="text-base md:text-lg  text-jr_pink  font-black">View All Selected Gifts</h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeddingGifts;
