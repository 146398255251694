import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { resetEmailState } from "../store/slices/email";
import { resetTokenState } from "../store/slices/token";
import { verifyEmail } from "../store/actions/email";
import { resendToken } from "../store/actions/token";
import ButtonLoader from "../components/ButtonLoader";
import { useSelector, useDispatch } from "react-redux";

const VerifyEmail = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState("");

  const emailLoading = useSelector((state) => state.email.bottonLoading);
  const tokenLoading = useSelector((state) => state.token.bottonLoading);

  const emailErrorMessage = useSelector((state) => state.email.errorMessage);
  const tokenErrorMessage = useSelector((state) => state.token.errorMessage);

  const emailSuccessMessage = useSelector(
    (state) => state.email.successMessage
  );
  const tokenSuccessMessage = useSelector(
    (state) => state.token.successMessage
  );

  useEffect(() => {
    if (emailErrorMessage) {
      toast.dismiss();
      toast.error(emailErrorMessage);
    }

    if (tokenErrorMessage) {
      toast.dismiss();
      toast.error(tokenErrorMessage);
    }

    if (tokenSuccessMessage) {
      toast.dismiss();
      toast.success(tokenSuccessMessage);
    }

    if (emailSuccessMessage) {
      navigate(`/verify-email-success`);
      setToken("");
    }

    return () => {
      dispatch(resetEmailState());
      dispatch(resetTokenState());
    };
  }, [
    emailErrorMessage,
    tokenErrorMessage,
    emailSuccessMessage,
    tokenSuccessMessage,
  ]);

  const submitForm = () => {
    toast.dismiss();

    if (token.trim() === "") {
      toast.error("Your token is required.");
      return;
    }

    if (!id) {
      toast.error(
        "Can't retrieve user details at this moment. Please try again later."
      );
      return;
    }

    const payload = {
      id,
      token,
    };

    console.log(payload);

    dispatch(verifyEmail(payload));
  };

  const resendUserToken = () => {
    if (!id) {
      toast.error(
        "Can't retrieve user details at this moment. Please try again later."
      );
      return;
    }

    dispatch(resendToken(id));
  };

  return (
    <section className="grid lg:grid-cols-2">
      <div className="h-full overflow-auto  py-12 z-50 w-full">
        <div className="container">
          <div className="sm:w-9/12 mx-auto hidden lg:block">
            <div className="mx-auto lg:mx-0 w-48">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>
          </div>

          <div className="w-full sm:w-9/12 sm:mx-auto mt-10 lg:mt-20">
            <div className="mb-6 w-36 block lg:hidden">
              <div className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 mb-6">
              <h3 className="text-2xl md:text-5xl text-black font-black">
                Verify Your Email.
              </h3>
              <p className="text-sm sm:text-base text-jr_slateGray font-medium">
                Please enter the token sent to your email.
              </p>
            </div>

            <div className="flex flex-col gap-5">
              <div>
                <label className="text-sm sm:text-base  font-bold text-black">
                  Enter token
                </label>
                <div className="relative border border-jr_grey mt-3">
                  <input
                    className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
                    type="text"
                    placeholder="Enter the token received"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  />
                </div>
              </div>

              <p className="text-right">
                {tokenLoading ? (
                  <button
                    className="text-sm text-jr_pink font-bold sm:text-base"
                    disabled
                  >
                    Sending new token....
                  </button>
                ) : (
                  <button
                    className="text-sm text-jr_pink font-bold sm:text-base"
                    onClick={resendUserToken}
                  >
                    Resend Token
                  </button>
                )}
              </p>

              <div className="flex">
                {emailLoading ? (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    disabled
                  >
                    <ButtonLoader />
                  </button>
                ) : (
                  <button
                    className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                    onClick={submitForm}
                  >
                    <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                      Submit
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" z-50  hidden lg:block bg-cover bg-no-repeat bg-[50%] bg-jr_auth fixed right-0 top-0 w-1/2 h-full ">
        <div className="bg-[#00000080] h-full  pt-12 pr-12">
          <div className="w-48 ml-auto">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/76e1df154768775.6365a98fb0df9.png"
              alt="joyribbons"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
