import React from "react";

const FeaturedVenues = () => {
  return (
    <>
      <div className="relative md:px-4 sm:h-[500px]">
        <div>
          <img
            className="max-h-[240px] w-full sm:min-w-[360px] h-[240px] mt-4 inline-block  max-w-full bg-white p-1 border border-jr_joy rounded"
            src="https://venue-hero.netlify.app/venuehero.co/uploads/230ac1c862d2c755ffef1c8a5286fc3b.jpg"
            alt="joyribbons"
          />
        </div>
        <div className="my-2">
          <h4 className="uppercase font-bold text-black">
            Swiss Int&lsquo;l Mabisel <br />
            <small className="text-jr_pink font-medium">Trans Amadi, Port Harcourt.</small>
          </h4>
        </div>
        <p className="my-2 text-sm text-jr_slateGray font-medium">
          Swiss International Mabisel, Port Harcourt, is a serene, quiet and safe magnificent edifice tucked away in the
          heart of Trans-Amadi, off Peter Odili Road. It boasts of 24/7 Power, ample car park space...
        </p>
        <hr className="my-5 border-t border-t-jr_joy" />
        <div className="text-center md:text-left">
          <div className="flex gap-3 items-center flex-col">
            <div className="self-center">
              <button className="text-sm">₦80,000</button>
            </div>
            <div className="flex gap-3 items-center justify-center flex-wrap">
              <div>
                <button className="px-3 py-[6px] rounded border border-jr_pink cursor-pointer text-sm text-jr_pink bg-white w-[120px] text-center">
                  Details
                </button>
              </div>
              <div>
                <button className="px-3 py-[6px] rounded border border-transparent cursor-pointer text-sm text-white bg-jr_pink w-[120px] text-center">
                  Book
                </button>
              </div>
            </div>
          </div>
          <hr className="my-5 border-t border-t-jr_joy" />

          {/* <div
            className="modal fade"
            id="16"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                    ×
                  </button>
                  <h4 className="modal-title" id="myModalLabel">
                    Select a hall from Swiss Int&lsquo;l Mabisel
                  </h4>
                </div>
                <div className="modal-body">
                  <form action="https://venuehero.co/book.php" method="get">
                    <select name="id">
                      <option value="45">Conference Hall 1</option>
                      <option value="46">Conference Hall 2</option>
                      <option value="47">Board Room 1</option>
                      <option value="48">Board Room 2</option>
                    </select>
                    <br />
                    <br />
                    <button className="btn book" type="submit">
                      Book
                    </button>
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn details" data-dismiss="modal">
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          {/* <hr className="my-5 border-t border-t-jr_joy" /> */}
        </div>
      </div>

      <div className="relative md:px-4 sm:h-[500px]">
        <div>
          <img
            className="max-h-[240px] w-full sm:min-w-[360px] h-[240px] mt-4 inline-block  max-w-full bg-white p-1 border border-jr_joy rounded"
            src="https://venue-hero.netlify.app/venuehero.co/uploads/019af028e7c6df765542de199363acca.jpg"
            alt="joyribbons"
          />
        </div>
        <div className="my-2">
          <h4 className="uppercase font-bold text-black">
            Swiss Int&lsquo;l Mabisel <br />
            <small className="text-jr_pink font-medium">Trans Amadi, Port Harcourt.</small>
          </h4>
        </div>
        <p className="my-2 text-sm text-jr_slateGray font-medium">
          Swiss International Mabisel, Port Harcourt, is a serene, quiet and safe magnificent edifice tucked away in the
          heart of Trans-Amadi, off Peter Odili Road. It boasts of 24/7 Power, ample car park space...
        </p>
        <hr className="my-5 border-t border-t-jr_joy" />
        <div className="text-center md:text-left">
          <div className="flex gap-3 items-center flex-col">
            <div className="self-center">
              <button className="text-sm">₦80,000</button>
            </div>
            <div className="flex gap-3 items-center justify-center flex-wrap">
              <div>
                <button className="px-3 py-[6px] rounded border border-jr_pink cursor-pointer text-sm text-jr_pink bg-white w-[120px] text-center">
                  Details
                </button>
              </div>
              <div>
                <button className="px-3 py-[6px] rounded border border-transparent cursor-pointer text-sm text-white bg-jr_pink w-[120px] text-center">
                  Book
                </button>
              </div>
            </div>
          </div>
          <hr className="my-5 border-t border-t-jr_joy" />
        </div>
      </div>

      <div className="relative md:px-4 sm:h-[500px]">
        <div>
          <img
            className="max-h-[240px] w-full sm:min-w-[360px] h-[240px] mt-4 inline-block  max-w-full bg-white p-1 border border-jr_joy rounded"
            src="https://venue-hero.netlify.app/venuehero.co/uploads/21a3bd07d19107b920e57a37cae899f5.jpg"
            alt="joyribbons"
          />
        </div>
        <div className="my-2">
          <h4 className="uppercase font-bold text-black">
            Swiss Int&lsquo;l Mabisel <br />
            <small className="text-jr_pink font-medium">Trans Amadi, Port Harcourt.</small>
          </h4>
        </div>
        <p className="my-2 text-sm text-jr_slateGray font-medium">
          Swiss International Mabisel, Port Harcourt, is a serene, quiet and safe magnificent edifice tucked away in the
          heart of Trans-Amadi, off Peter Odili Road. It boasts of 24/7 Power, ample car park space...
        </p>
        <hr className="my-5 border-t border-t-jr_joy" />
        <div className="text-center md:text-left">
          <div className="flex gap-3 items-center flex-col">
            <div className="self-center">
              <button className="text-sm">₦80,000</button>
            </div>
            <div className="flex gap-3 items-center justify-center flex-wrap">
              <div>
                <button className="px-3 py-[6px] rounded border border-jr_pink cursor-pointer text-sm text-jr_pink bg-white w-[120px] text-center">
                  Details
                </button>
              </div>
              <div>
                <button className="px-3 py-[6px] rounded border border-transparent cursor-pointer text-sm text-white bg-jr_pink w-[120px] text-center">
                  Book
                </button>
              </div>
            </div>
          </div>
          <hr className="my-5 border-t border-t-jr_joy" />
        </div>
      </div>
    </>
  );
};

export default FeaturedVenues;
