import React, { useState } from "react";
import PropTypes from "prop-types";
import axiosInstance from "../Axios";
import { useDispatch } from "react-redux";
import { logOut } from "../store/slices/auth";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const dispatch = useDispatch();

  const initialAuthState = {
    isAuth: null,
    user: null,
    exp: -1,
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = userData?.token;
  const expiryDate = localStorage.getItem("expirationDate");

  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  const [auth, setAuth] = useState({
    ...initialAuthState,
    user: userData || null,
    isAuth: !!token,
    exp: expiryDate || -1,
  });

  const handleAuthSuccess = (newUser) => {
    let expiresIn = newUser.expires_in * 1000;
    expiresIn = expiresIn - 60000;
    const expirationDate = new Date().getTime() + expiresIn;
    // state.expireTimer = expiresIn;

    setAuth(() => ({
      user: newUser,
      isAuth: true,
      exp: expirationDate,
    }));

    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${newUser.token}`;

    localStorage.setItem("expirationDate", String(expirationDate));
    localStorage.setItem("userData", JSON.stringify(newUser));
  };

  const handleAuthLogout = () => {
    dispatch(logOut());
    setAuth(() => initialAuthState);
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userData");
  };

  const finalAuthState = {
    state: auth,
    onAuthSuccess: handleAuthSuccess,
    onAuthLogout: handleAuthLogout,
  };

  return (
    <AuthContext.Provider value={finalAuthState}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthState() {
  const authState = React.useContext(AuthContext);

  return authState.state;
}

export function useAuth() {
  const authState = React.useContext(AuthContext);

  return {
    onAuthSuccess: authState.onAuthSuccess,
    onAuthLogout: authState.onAuthLogout,
  };
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
