import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../actions/register";

const initialState = {
  bottonLoading: false,
  errorMessage: "",
  successMessage: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetRegisterState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export const { resetRegisterState } = registerSlice.actions;
export default registerSlice.reducer;
