import React, { useState, useEffect } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updatePassword } from "../store/actions/password";
import ButtonLoader from "../components/ButtonLoader";
import { resetPasswordState } from "../store/slices/password";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [openPassword, setOpenPassword] = useState(<BsFillEyeFill size={18} />);
  const [openConfirmPassword, setOpenConfirmPassword] = useState(
    <BsFillEyeFill size={18} />
  );
  const [openCurrentPassword, setOpenCurrentPassword] = useState(
    <BsFillEyeFill size={18} />
  );
  const bottonLoading = useSelector((state) => state.password.bottonLoading);
  const errorMessage = useSelector((state) => state.password.errorMessage);
  const successMessage = useSelector((state) => state.password.successMessage);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      toast.error(errorMessage);
    }

    if (successMessage) {
      toast.dismiss();
      toast.success(successMessage);

      setFormDetails({
        ...formDetails,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }

    return () => {
      dispatch(resetPasswordState());
    };
  }, [errorMessage, successMessage]);

  const showCurrentPasswordType = () => {
    if (currentPasswordType === "password") {
      setCurrentPasswordType("text");
      setOpenCurrentPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setCurrentPasswordType("password");
      setOpenCurrentPassword(<BsFillEyeFill size={18} />);
    }
  };

  const showPasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setOpenPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setPasswordType("password");
      setOpenPassword(<BsFillEyeFill size={18} />);
    }
  };

  const showConfirmPasswordType = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      setOpenConfirmPassword(<BsFillEyeSlashFill size={18} />);
    } else {
      setConfirmPasswordType("password");
      setOpenConfirmPassword(<BsFillEyeFill size={18} />);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    toast.dismiss();

    const validateData =
      formDetails.oldPassword.trim() !== "" &&
      formDetails.newPassword.trim() !== "" &&
      formDetails.confirmPassword.trim() !== "";

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    if (formDetails.newPassword.trim() !== formDetails.confirmPassword.trim()) {
      toast.error("New password and confirm password are not the same.");
      return;
    }

    if (formDetails.newPassword.trim() === formDetails.oldPassword.trim()) {
      toast.error("New password and old password are the same.");
      return;
    }

    let payload = {
      old_password: formDetails.oldPassword.trim(),
      new_password: formDetails.newPassword.trim(),
    };

    dispatch(updatePassword(payload));
  };

  return (
    <div>
      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Enter Current Password
        </label>

        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type={currentPasswordType}
            placeholder="current password"
            value={formDetails.oldPassword}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                oldPassword: e.target.value,
              })
            }
          />
          <div
            onClick={showCurrentPasswordType}
            className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
          >
            {openCurrentPassword}
          </div>
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          New Password
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type={passwordType}
            placeholder="new password"
            value={formDetails.newPassword}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                newPassword: e.target.value,
              })
            }
          />
          <div
            onClick={showPasswordType}
            className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
          >
            {openPassword}
          </div>
        </div>
      </div>

      <div className="mb-8">
        <label className="text-sm sm:text-base  font-bold text-black">
          Confirm New Password
        </label>
        <div className="relative border border-jr_grey mt-3">
          <input
            className="bg-transparent text-black text-sm  font-semibold h-12 px-6 w-full"
            type={confirmPasswordType}
            placeholder="confirm new password"
            value={formDetails.confirmPassword}
            onChange={(e) =>
              setFormDetails({
                ...formDetails,
                confirmPassword: e.target.value,
              })
            }
          />
          <div
            onClick={showConfirmPasswordType}
            className="flex absolute cursor-pointer top-0 right-0 bottom-0 items-center flex-row flex-nowrap w-10"
          >
            {openConfirmPassword}
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-auto sm:ml-auto sm:mr-0 mt-12">
          {bottonLoading ? (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              disabled
            >
              <ButtonLoader />
            </button>
          ) : (
            <button
              className="bg-jr_pink w-full flex items-center content-center cursor-pointer text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
              onClick={submitForm}
            >
              <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                Change Password
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
