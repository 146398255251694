import { createSlice } from "@reduxjs/toolkit";
import {
  updatePassword,
  forgotPassword,
  resetPassword,
} from "../actions/password";

const initialState = {
  bottonLoading: false,
  errorMessage: "",
  successMessage: "",
};
export const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    resetPasswordState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (typeof action.payload === "string") {
        msg = action.payload;
      } else if (typeof action.payload.message === "string") {
        msg = action.payload.message;
      }
      state.errorMessage = msg;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (typeof action.payload === "string") {
        msg = action.payload;
      } else if (typeof action.payload.message === "string") {
        msg = action.payload.message;
      }
      state.errorMessage = msg;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export const { resetPasswordState } = passwordSlice.actions;
export default passwordSlice.reducer;
