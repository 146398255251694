import { createSlice } from "@reduxjs/toolkit";
import { getWeddingStories, updateWeddingStories } from "../actions/story";

const initialState = {
  weddingStories: null,
  pageLoading: false,
  bottonLoading: false,
  errorState: false,
  errorMessage: "",
  successMessage: "",
};

export const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    resetStoryState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.errorState = false;
      state.pageLoading = false;
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWeddingStories.pending, (state) => {
      state.pageLoading = true;
      state.bottonLoading = false;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(getWeddingStories.fulfilled, (state, action) => {
      state.weddingStories = action.payload.data;
      state.pageLoading = false;
    });
    builder.addCase(getWeddingStories.rejected, (state, action) => {
      state.pageLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
    builder.addCase(updateWeddingStories.pending, (state) => {
      state.pageLoading = false;
      state.bottonLoading = true;
      state.errorState = false;
      state.errorMessage = "";
      state.successMessage = "";
    });
    builder.addCase(updateWeddingStories.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateWeddingStories.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";

      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }

      state.errorMessage = msg;
      state.pageLoading = false;
      state.errorState = true;
    });
  },
});

export const { resetStoryState } = storySlice.actions;
export default storySlice.reducer;
