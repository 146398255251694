import React from "react";
import { Link, NavLink } from "react-router-dom";
import { RiArrowDownSLine, RiMenu3Fill } from "react-icons/ri";
import PropTypes from "prop-types";
import { useAuth } from "../context/AuthContext";
import { logOutUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

const Navbar = ({ toggle }) => {
  const dispatch = useDispatch();
  const { onAuthLogout } = useAuth();

  const navLinks = ({ isActive }) => {
    return isActive ? "active-navLink" : "navLink";
  };

  const logUserOut = () => {
    dispatch(logOutUser());
    onAuthLogout();
  };

  return (
    <section className="h-20 shadow-[inset_0_-1px_#e2e2ea]  w-full">
      <div className="container flex justify-center items-center h-20">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-between items-center w-full md:w-auto">
            <div className="w-40 md:w-48">
              <Link to="/overview" className="w-full">
                <img
                  src="https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/f8de1b154768775.6365a98fb01d7.png"
                  alt="joyribbons"
                />
              </Link>
            </div>

            <div className="flex md:hidden cursor-pointer" onClick={toggle}>
              <RiMenu3Fill color="#f9508c" size={24} />
            </div>
          </div>

          <div className="hidden md:flex gap-5 ">
            <div className="text-base font-semibold lg:hover:underline relative">
              <NavLink className={navLinks} to="/overview">
                Overview
              </NavLink>
            </div>

            <div className="text-base font-semibold lg:hover:underline relative">
              <NavLink className={navLinks} to="/analytics">
                Analytics
              </NavLink>
            </div>

            <div className="text-base font-semibold lg:hover:underline relative cursor-pointer dropdown inline-block group">
              <div className="flex items-center justify-center transition-all duration-500">
                My Wedding Website <RiArrowDownSLine />
              </div>

              <div className="dropdown-content absolute left-0 z-50 hidden p-4 text-gray-700 bg-white shadow-[0px_0px_1px_1px_#00000017] -translate-x-1/4 w-[200px] rounded-xl group-hover:flex">
                <div className="w-full grid gap-3">
                  <div>
                    <NavLink className={navLinks} to="/wedding-registry">
                      Website Details
                    </NavLink>
                  </div>

                  <div>
                    <NavLink className={navLinks} to="/registry-link">
                      My Wedding Link
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="text-base font-semibold lg:hover:underline relative">
              <NavLink className={navLinks} to="/wedding-venue">
                Search Wedding Venue
              </NavLink>
            </div> */}

            <div className="text-base font-semibold lg:hover:underline relative cursor-pointer dropdown inline-block group">
              <div className="flex items-center justify-center transition-all duration-500">
                Settings <RiArrowDownSLine />
              </div>

              <div className="dropdown-content absolute left-0 z-50 hidden p-4 text-gray-700 bg-white shadow-[0px_0px_1px_1px_#00000017] -translate-x-2/3 w-[200px] rounded-xl group-hover:flex">
                <div className="w-full grid gap-3">
                  <div>
                    <NavLink className={navLinks} to="/profile">
                      Update Profile
                    </NavLink>
                  </div>
                  <div>
                    <div
                      className="text-base font-semibold lg:hover:underline relative text-jr_red"
                      onClick={logUserOut}
                    >
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Navbar.propTypes = {
  toggle: PropTypes.func,
};

export default Navbar;
