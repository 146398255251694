import React from "react";
import { VscChromeClose } from "react-icons/vsc";
import PropTypes from "prop-types";

const WithdrawModal = ({ closeModal }) => {
  return (
    <section>
      <div className="bg-[#00000087] z-50 flex flex-row flex-wrap h-full justify-center items-center fixed inset-0 w-full overflow-auto transition-all duration-700 ease-out">
        <div className="max-w-[600px] w-full py-24 p-3 m-0">
          <div className="cursor-pointer flex justify-end items-center  mb-3">
            <button className="bg-white w-8 h-8 rounded-full flex justify-center items-center">
              <VscChromeClose color="#f9508c" size={20} onClick={closeModal} />
            </button>
          </div>
          <div className="bg-white rounded-xl p-4 sm:p-9 shadow-[0_2px_8px_#0003]">
            <div>
              <div className="flex flex-col justify-between items-center flex-wrap sm:flex-nowrap gap-6">
                <h3 className="text-lg md:text-xl font-black text-jr_pink">
                  Withdrawal
                </h3>

                <p className="text-sm sm:text-base">
                  &ldquo;We have taken note of your decision to withdraw and we
                  would credit your account in 2-3 working days&ldquo;.
                  <br />
                  Please ensure that the account number in your profile is same
                  as your name.
                  <br /> <br />
                  We would also send you an email
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

WithdrawModal.propTypes = {
  closeModal: PropTypes.func,
};

export default WithdrawModal;
