import React from "react";

function PageLoader() {
  return (
    <div className="loader_page">
      <div></div>
    </div>
  );
}

export default PageLoader;
