import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { BsExclamationLg } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { getRegistryDetails, createRegistry } from "../store/actions/registry";
import { resetRegistryState } from "../store/slices/registry";
import PageLoader from "../components/PageLoader";
import NoRecordFound from "../components/NoRecordFound";
import ErrorFound from "../components/ErrorFound";
import { toast } from "react-toastify";

const Registry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageLoading = useSelector((state) => state.registry.pageLoading);
  const errorMessage = useSelector((state) => state.registry.errorMessage);
  const successMessage = useSelector((state) => state.registry.successMessage);
  const emptyState = useSelector((state) => state.registry.emptyState);
  const errorState = useSelector((state) => state.registry.errorState);
  const registryDetails = useSelector(
    (state) => state.registry.registryDetails
  );

  useEffect(() => {
    dispatch(getRegistryDetails());

    return () => {
      dispatch(resetRegistryState());
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      navigate("/registry-link");
    }
  }, [successMessage]);

  const submitRegistry = async () => {
    toast.dismiss();

    const validateData =
      registryDetails.wedding !== false &&
      registryDetails.story !== false &&
      registryDetails.picture !== false &&
      registryDetails.gift !== 0 &&
      registryDetails.cash !== false;

    if (!validateData) {
      toast.error("All fields are required.");
      return;
    }

    dispatch(createRegistry());
  };

  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="mb-12">
              <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                My Wedding Website
              </h3>
            </div>

            {pageLoading && <PageLoader />}
            {!pageLoading && emptyState && (
              <div>
                <NoRecordFound title="Oops! no record available at the moment. " />
              </div>
            )}
            {!pageLoading && errorState && (
              <div>
                <ErrorFound title={errorMessage} />
              </div>
            )}

            {registryDetails && (
              <>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 my-6 gap-5">
                  <Link
                    to="/wedding-details"
                    style={{
                      backgroundImage: `url(
                      "https://plus.unsplash.com/premium_photo-1673343398580-4c26b53039b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=477&q=80"
                    )`,
                    }}
                    className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                  >
                    <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                      <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                        Wedding Details
                      </h3>
                      <div>
                        {registryDetails.wedding ? (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "#18bf18" }}
                          >
                            <span>
                              <IoMdCheckmark size={24} />
                            </span>
                            Completed
                          </h5>
                        ) : (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "red" }}
                          >
                            <span>
                              <BsExclamationLg size={24} />
                            </span>
                            Not Filled
                          </h5>
                        )}
                      </div>
                      <div className="absolute bottom-3 px-3 w-full">
                        <button className="bg-white w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center">
                          Update
                        </button>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to="/love-story"
                    style={{
                      backgroundImage: `url(
                      "https://images.unsplash.com/photo-1463438690606-f6778b8c1d10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                    )`,
                    }}
                    className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                  >
                    <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                      <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                        Love Story
                      </h3>
                      <div>
                        {registryDetails.story ? (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "#18bf18" }}
                          >
                            <span>
                              <IoMdCheckmark size={24} />
                            </span>
                            Completed
                          </h5>
                        ) : (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "red" }}
                          >
                            <span>
                              <BsExclamationLg size={24} />
                            </span>
                            Not Filled
                          </h5>
                        )}
                      </div>
                      <div className="absolute bottom-3 px-3 w-full">
                        <button className="bg-white w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center">
                          Update
                        </button>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to="/wedding-pictures"
                    style={{
                      backgroundImage: `url(
                      "https://plus.unsplash.com/premium_photo-1668790939919-08e863d23c86?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=411&q=80"
                    )`,
                    }}
                    className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                  >
                    <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                      <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                        Wedding Pictures
                      </h3>
                      <div>
                        {registryDetails.picture ? (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "#18bf18" }}
                          >
                            <span>
                              <IoMdCheckmark size={24} />
                            </span>
                            Completed
                          </h5>
                        ) : (
                          <h5
                            className="flex justify-center items-center text-base"
                            style={{ color: "red" }}
                          >
                            <span>
                              <BsExclamationLg size={24} />
                            </span>
                            Not Filled
                          </h5>
                        )}
                      </div>
                      <div className="absolute bottom-3 px-3 w-full">
                        <button className="bg-white w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center">
                          Update
                        </button>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to="/wedding-gifts"
                    style={{
                      backgroundImage: `url(
                      "https://images.unsplash.com/photo-1506792006437-256b665541e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                    )`,
                    }}
                    className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                  >
                    <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                      <div>
                        <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                          Physical Gifts
                        </h3>

                        <div>
                          <h3
                            className="text-center text-3xl lg:text-5xl text-white font-semibold"
                            style={{ color: "#ff1493" }}
                          >
                            {registryDetails.gift}{" "}
                            <sub className="text-base font-medium text-white">
                              Gift(s) added
                            </sub>
                          </h3>
                        </div>
                      </div>
                      <div className="absolute bottom-3 px-3 w-full">
                        <button className="bg-white w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center">
                          Update
                        </button>
                      </div>
                    </div>
                  </Link>

                  <Link
                    to="/cash-gifts"
                    style={{
                      backgroundImage: `url(
                      "https://images.unsplash.com/photo-1464820453369-31d2c0b651af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
                    )`,
                    }}
                    className="bg-[50%] overflow-hidden cursor-pointer rounded-md bg-cover bg-no-repeat"
                  >
                    <div className="flex flex-col flex-wrap px-5 relative justify-center items-center bg-[#000000a8] min-h-[350px]">
                      <div>
                        <h3 className="text-center text-xl md:text-2xl text-white font-semibold">
                          Cash Gifts
                        </h3>
                        <div>
                          {registryDetails.cash ? (
                            <h5
                              className="flex justify-center items-center text-base"
                              style={{ color: "#18bf18" }}
                            >
                              <span>
                                <IoMdCheckmark size={24} />
                              </span>
                              Completed
                            </h5>
                          ) : (
                            <h5
                              className="flex justify-center items-center text-base"
                              style={{ color: "red" }}
                            >
                              <span>
                                <BsExclamationLg size={24} />
                              </span>
                              Not Filled
                            </h5>
                          )}
                        </div>
                      </div>
                      <div className="absolute bottom-3 px-3 w-full">
                        <button className="bg-white w-full flex items-center content-center cursor-pointer text-sm uppercase mt-3 flex-nowrap font-black h-12 justify-center">
                          Update
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="mt-24 mx-auto max-w-[350px]">
                  <div>
                    {!pageLoading && registryDetails.status === "PENDING" && (
                      <button
                        className="bg-jr_pink  w-full flex items-center content-center cursor-pointer  text-sm sm:text-base flex-nowrap font-black h-12 justify-center"
                        onClick={submitRegistry}
                      >
                        <span className="text-sm text-white sm:text-base font-black flex items-center justify-center">
                          Get Your Wedding Website Link
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registry;
