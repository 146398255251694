import { createSlice } from "@reduxjs/toolkit";
import { updateAccount } from "../actions/account";

const initialState = {
  bottonLoading: false,
  errorMessage: "",
  successMessage: "",
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    resetAccountState: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAccount.pending, (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.bottonLoading = true;
    });
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      state.bottonLoading = false;
      state.errorMessage = "";
      const userData = JSON.parse(localStorage.getItem("userData"));
      userData.user = action.payload.data;
      localStorage.setItem("userData", JSON.stringify(userData));
      state.successMessage = action.payload.message;
    });
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.bottonLoading = false;
      let msg = "Something went wrong, Please try again later.";
      if (action.payload) {
        if (typeof action.payload === "string") {
          msg = action.payload;
        } else if (typeof action.payload.message === "string") {
          msg = action.payload.message;
        }
      }
      state.errorMessage = msg;
    });
  },
});

export const { resetAccountState } = accountSlice.actions;
export default accountSlice.reducer;
