import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Axios";
import { AxiosError } from "axios";

export const forgotPassword = createAsyncThunk(
  "password/forgotPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`forgotPassword`, payload);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const resetPassword = createAsyncThunk(
  "password/resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`resetPassword`, payload);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);

export const updatePassword = createAsyncThunk(
  "password/updatePassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`users/passwords`, payload);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          return rejectWithValue(error.response.data.message);
        }
      } else {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
      }
    }
  }
);
