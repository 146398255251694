import React from "react";
import ChangePassword from "../components/ChangePassword";
import BankDetails from "../components/BankDetails";
import PersonalInformation from "../components/PersonalInformation";

const Profile = () => {
  return (
    <section className="pt-12">
      <div className="container">
        <div className="flex flex-col justify-start  min-h-[600px] sm:min-h-[65vh]  pb-24 overflow-x-hidden">
          <div>
            <div className="flex justify-between mb-6 flex-row gap-6 flex-wrap items-center">
              <div>
                <h3 className="text-2xl md:text-4xl  text-jr_black font-black">
                  Settings
                </h3>
              </div>
            </div>
          </div>

          <div className="py-12">
            <div className="w-full">
              <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
                <div className="flex flex-col gap-5">
                  <div className="text-left">
                    <h3 className="text-lg md:text-2xl  text-black  font-black">
                      Personal Information
                    </h3>
                  </div>

                  <PersonalInformation />
                </div>

                <div className="flex flex-col gap-5 w-full">
                  <div className="text-left">
                    <h3 className="text-lg md:text-2xl  text-black  font-black">
                      Change Password
                    </h3>
                  </div>

                  <ChangePassword />
                </div>

                <div className="flex flex-col gap-5 w-full">
                  <div className="text-left">
                    <h3 className="text-lg md:text-2xl  text-black  font-black">
                      Bank Details
                    </h3>
                  </div>

                  <BankDetails />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
